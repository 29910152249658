:root {
    --bgDark: #0E0E2C;
    --bgDarkGrey:#ECF1F4;
    --bgDarkGrey-95:rgba(49,60,70,0.75);
    --bgGrey: #FFFFFF;
    --bgGreen: #389738;
    --bgDarkGreen: #4DB205;
    --bgBlack: #000000;
    --bgYellow: #FFDB1A;
    --bgDarkYellow:#e0bb02;
    --bgWhite: #FFFFFF;
    --bgOrient: #1E526E;
    --bgGloom: #FFFFFF;
    --bgBlue: #1E90FF;
    --bgPitch: #1B4156;
    --bgBlu: #ecf1f4;
    --textPureWhite: #FFFFFF;
    --textWhite: #FFFFFF;
    --textWhite30: #243540;
    --textWhite50: #717171;
    --textSecondary: #9797AA;
    --textDark: #000000;
    --textGreen: #389738;
    --textYellow: #0E0E2C;
    --textDefautlYlw: #FFDB1A;
    --textRed: #FF3A1A;
    --textAqua: #2AAECC;
    --textLink:#0b81d0;
    --textpurple:#5C5C77;
    --textDarkGreen: #4DB205;
    --textError: #FF2400;
    --btnGrad1: #B30F1E;
    --btnGrad2: #0038FF;
    --btnBorder: #5C5C77;
    --borderGrey: #E9F3FF;
    --borderLight: #CECECE;
    --bgCircle: #19B06C;
    --lightThemeIcons: url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/admin-light-theme-icons.svg);
    --cryptoIcons: url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/dark-crypto-icons.svg);
    /*--logo: url(../../assets/images/logo-color.png); */
}