@import url('../fonts/stylesheet.css');
/* * { scrollbar-width: thin; scrollbar-color: var(--bgGrey) var(--bgDarkGrey);} */
::-webkit-scrollbar-corner {background: var(--scroll);}
*::-webkit-scrollbar { width: 12px;height: 10px;}
*::-webkit-scrollbar-thumb {background-color: var(--bgDark);border-radius: 20px;}
body, h1, h2, h3, h4, h5, h6, a, p div, .ant-statistic-content{font-family: 'SF Pro Text', sans-serif !important;}
.ant-layout, body{background-color: var(--bgDarkGrey);}
.ant-modal-content{border-radius: 24px;overflow: hidden;}
.ant-modal-header{border-radius: 24px 24px 0 0;padding: 16px;}
.ant-modal-title{font-size: 18px; line-height: 34px;color: var(--textWhite30);}
.ant-modal-body p{font-size: 16px;color: var(--textWhite50);font-weight: 400;}
.ant-modal-footer{padding: 16px;}
.ant-modal-close{top: 4px;right: 6px;}
.tlv-logo{height: 45px;padding-left: 24px;}
.border-bottom{border-bottom:1px solid #f0f0f0}
header.tlv-header{
    z-index: 999 !important;
    /* z-index: 1052 !important; */
}
.tlv-header{background-color: var(--bgWhite) !important;position: fixed;
     z-index: 99;
     /* z-index: 1051; */
      width: 100%;}
.sidernav .ant-menu-item a{color: var(--textWhite30); font-size: 16px;}
.sidernav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-image: linear-gradient(99.09deg, var(--btnGrad1) 0%, var(--btnGrad2) 100%);}
.sidernav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content a{color: var(--textWhite); font-weight: 300;}
.sidernav .ant-menu-inline .ant-menu-item::after{border-right: 0;}
.icon,.custom-checkbox input[type=checkbox]+span,.custom-checkbox input[type=checkbox]:checked+span, .k-grouping-row .k-icon, .k-icon.k-i-more-vertical, .ant-checkbox .ant-checkbox-inner, .checkbox-active input[type=checkbox]+span,.checkbox-active input[type=checkbox]:checked+span,.poa-accordian .ant-collapse-header::after {background: var(--lightThemeIcons) no-repeat; display: inline-block; vertical-align: middle;}
.icon.xxxl{width: 70px; height: 70px;}
.icon.xl{width: 32px; height: 32px;}
.icon.lg{width: 26px; height: 26px;}
.icon.md{width: 20px; height: 20px;}
.icon.sm{width: 12px; height: 12px;}
.icon.md.addresstype{background-position: -854px -28px;}
.icon.md.gear{background-position: -30px -1px;}
.icon.md.add{background-position: -2px -30px;}
.icon.md.plus{background-position: -601px -59px;}
.icon.md.edit{background-position:-31px -30px;}
.icon.md.verification{background-position:-575px -59px;}
.icon.md.state, .icon.md.state-change{background-position: -282px -60px;}
.icon.md.excelexport{background-position: -211px -29px;}
.icon.md.more{background-position:-58px -30px;}
.icon.md.Doc.Request, .icon.md.Request.Document{background-position: -362px -59px;}
.icon.md.rarrow-white { background-position: -217px -1px;}
.icon.md.x{ background-position: -168px -1px;}
.icon.md.email{background-position: -334px -59px;}
.icon.md.back-arrow-icon{ background-position: -375px 2px;}
.icon.md.submitted{background-position: -576px -31px;}
.icon.md.cancel, .icon.md.Dismiss{background-position: -545px -31px;}
.icon.md.delete{background-position: -516px -31px;}
.icon.md.downangle{background-position: -352px 0;}
.icon.md.upangle{background-position: -352px 0;}
.icon.md.greyCheck{background-position: -628px -30px;}
.icon.md.greenCheck{background-position: -602px -30px;}
.icon.md.redclose{background-position: -831px -29px}
.icon.md.close{background-position: -512px -2px;}
.icon.md.attach{background-position: -573px -2px;}
.icon.md.send-icon{background-position:-545px -4px;}
.icon.md.reply{background-position:-602px -4px;}
.icon.md.info{background-position:-631px -3px;}
.icon.md.profit-arw{background-position:-386px -59px;}
.icon.md.lose-arw{background-position:-408px -59px;}
.icon.md.share{background-position:-628px -58px;}
.icon.md.case{background-position: -800px -30px;}
.icon.lg.withdraw,.icon.lg.openpaydwithdrwal{background-position:-37px -95px;}
.icon.lg.toggle-icon{background-position: -152px -95px}
.icon.lg.dashboard{background-position:-74px -95px;}
.icon.lg.customer,.icon.lg.openpaydcustomer{background-position: -2px -95px;}
.icon.lg.bank{background-position: -74px -95px;}
.icon.lg.bs{background-position: -194px -95px;}
.icon.lg.swap{background-position: -234px -95px;}
.icon.lg.dw{background-position: -269px -95px;}
.icon.lg.controlcode{background-position: -514px -91px;}
.icon.lg.compliance{background-position: -583px -91px;}
.icon.lg.auditlog{background-position: -269px -95px;}
.icon.lg.openpaid{background-position:-913px -25px;}
.icon.lg.pyrros{background-position:-949px -25px;}
.icon.lg.transaction,.icon.lg.openpaydtransaction{background-position:-370px -92px;}
.icon.lg.deposit,.icon.lg.openpayddeposit{background-position:-335px -92px;}
.icon.lg.member_deposit_crypto{background-position:-335px -92px;}
.icon.lg.notice,.icon.lg.Reports{background-position:-304px -92px;}
.icon.lg.documents{background-position:-403px -91px;}
.icon.lg.roles{background-position:-438px -91px;}
.icon.lg.users{background-position:-476px -91px;}
.icon.lg.commission{background-position:-549px -90px;}
.icon.lg.uploadmasspayments{background-position:-649px -90px;}
.icon.lg.masspayments{background-position:-617px -89px;}
.icon.lg.payments{background-position:-682px -89px;}
.icon.lg.alerts{background-position:-749px -89px;}
.icon.lg.cases{background-position:-718px -88px;}
.icon.lg.addressbook{background-position:-786px -88px;}
.icon.lg.selfhostedwallets{background-position:-1157px -84px;}
.icon.xl.image{background-position:-182px -156px;}
.icon.xl.file{background-position:-222px -156px}
.icon.xxxl.doc-upload{background-position:2px -240px}
.icon.xl.docu{background-position:-395px -197px;}
.icon.lg.reconcile{background-position:-817px -89px;}
.icon.lg.balances{background-position: -259px -163px;}
.icon.lg.referral{background-position: -369px -159px;}
.icon.lg.bonus{background-position: -431px -159px;}
.icon.lg.transactionscompliance{background-position: -850px -90px;}
.icon.md.Withdraw{background-position: -844px -57px;}
.icon.lg.search-check{background-position:-86px -262px;}
.icon.lg.search-checked{background-position:-125px -262px;}
.icon.lg.info{background-position: -158px -261px;}
.icon.md.retry{background-position: -818px -58px;}
.icon.lg.companybanks{background-position: -387px -267px;}
/* .icon.lg.pyrros{background-position: -883px -85px;} */
.icon.lg.evolve{background-position: -1054px -85px;}
.icon.lg.pyrroscustomer{background-position: -2px -95px;}
.icon.lg.pyrroswithdrwal{background-position: -37px -95px;}
.icon.lg.pyrrosdeposit,.icon.lg.pendingdepostis{background-position: -335px -90px;}
.icon.lg.pyrrostransaction{background-position: -370px -91px;}
.icon.lg.evolvewithdrwal{background-position: -37px -95px;}
.icon.lg.evolvedeposit{background-position: -335px -90px}
.icon.lg.evolvecustomer{background-position: -2px -95px;}
.icon.lg.evolvetransaction{background-position: -370px -91px;}
.ant-menu-item-selected .icon.lg.Cards{background-position: -1086px -123px;}

.ant-menu-item-selected .icon.lg.customer,.ant-menu-item-selected .icon.lg.openpaydcustomer{background-position:-2px -125px;}
.ant-menu-item-selected .icon.lg.dashboard{background-position: -114px -125px;}
.ant-menu-item-selected .icon.lg.companybanks{background-position: -426px -267px;}
.ant-menu-item-selected .icon.lg.withdraw,.ant-menu-item-selected .icon.lg.openpaydwithdrwal{background-position:-37px -126px;}
.ant-menu-item-selected .icon.lg.bank{background-position:-74px -126px;}
.ant-menu-item-selected .icon.lg.bs{background-position:-194px -126px;}
.ant-menu-item-selected .icon.lg.swap{background-position:-234px -126px;}
.ant-menu-item-selected .icon.lg.dw{background-position:-269px -126px;}
.ant-menu-item-selected .icon.lg.auditlog{background-position:-269px -126px;}
.ant-menu-item-selected .icon.lg.notice,.ant-menu-item-selected .icon.lg.Reports{background-position:-304px -124px;}
.ant-menu-item-selected .icon.lg.transaction{background-position:-370px -124px;}
.ant-menu-item-selected .icon.lg.deposit,.ant-menu-item-selected .icon.lg.member_deposit_crypto,.ant-menu-item-selected .icon.lg.openpayddeposit,.ant-menu-item-selected .icon.lg.pendingdepostis{background-position:-335px -124px;}
.ant-menu-item-selected .icon.lg.documents{background-position:-403px -124px;}
.ant-menu-item-selected .icon.lg.roles{background-position:-438px -122px;}
.ant-menu-item-selected .icon.lg.users{background-position:-476px -122px;}
.ant-menu-item-selected .icon.lg.controlcode{background-position:-514px -122px;}
.ant-menu-item-selected .icon.lg.compliance{background-position: -583px -122px;}
.ant-menu-item-selected .icon.lg.commission{background-position:-549px -122px;}
.ant-menu-item-selected .icon.lg.uploadmasspayments{background-position:-649px -123px;}
.ant-menu-item-selected .icon.lg.masspayments{background-position:-617px -123px;}
.ant-menu-item-selected .icon.lg.payments{background-position:-682px -123px;}
.ant-menu-item-selected .icon.lg.alerts{background-position:-749px -121px;}
.ant-menu-item-selected .icon.lg.cases{background-position:-718px -122px;}
.ant-menu-item-selected .icon.lg.addressbook{background-position:-786px -121px;}
.ant-menu-item-selected .icon.lg.reconcile {background-position:-817px -124px;}
.ant-menu-item-selected .icon.lg.balances{background-position: -288px -163px;}
.ant-menu-item-selected .icon.lg.referral{background-position: -335px -159px;}
.ant-menu-item-selected .icon.lg.bonus{background-position: -399px -159px;}
.ant-menu-item-selected .icon.lg.transactionscompliance{background-position: -850px -121px;}
.ant-menu-item-selected .icon.lg.companywallets{background-position: -881px -126px;}
.ant-menu-item-selected .icon.lg.transfer{background-position: -914px -127px;}
.ant-menu-item-selected .icon.lg.pyrroscustomer{background-position: -2px -125px;}
.ant-menu-item-selected .icon.lg.pyrroswithdrwal{background-position: -38px -123px;}
.ant-menu-item-selected .icon.lg.pyrrosdeposit{background-position: -335px -123px;}
.ant-menu-item-selected .icon.lg.pyrrostransaction,.ant-menu-item-selected .icon.lg.openpaydtransaction{background-position: -370px -125px;}
.ant-menu-item-selected .icon.lg.evolvecustomer{background-position: -2px -125px;}
.ant-menu-item-selected .icon.lg.evolvewithdrwal{background-position: -38px -123px;}
.ant-menu-item-selected .icon.lg.evolvedeposit{background-position: -335px -123px;}
.ant-menu-item-selected .icon.lg.evolvetransaction{background-position: -370px -125px;}
.ant-menu-item-selected .icon.lg.selfhostedwallets{background-position:-1157px -122px;}

.secureDropdown .ant-dropdown-menu {background-color: var(--bgGloom);border-radius: 10px;min-width: 300px;max-width: 300px;padding: 24px 0;
max-height: calc(100vh - 75px); overflow-y: auto;}
/* .secureDropdown.ant-dropdown{top:55px !important}
/* .secureDropdown.appDropdown .ant-dropdown-menu{min-width: 350px;max-width: 350px;} */ */
.secureDropdown ul.drpdwn-list .userdrp {font-size: 14px;padding: 0 30px !important;transition: 0.3s ease;list-style: none;}
.drpdwn-list li a {color: var(--textDark30); display: flex; align-items: center;  padding: 10px 0;}
.dropdown-flex { border-bottom: 1px solid var(--bgDarkGrey);display: flex;justify-content: space-between; align-items: center;}
.drpdwn-list li:hover {background-color: var(--bgDarkGrey);}
.cust-input .ant-select-selector{background-color: transparent;padding-left: 16px !important;}
.cust-input, .expiry-input,.cust-input .ant-input{background: transparent;border-radius: 30px;height: 38px;border: 1px solid var(--borderLight);color: var(--textWhite30);font-size: 16px;padding: 0 16px !important;}
.multi-select .ant-select-selector{border-radius: 30px !important;height: 40px;line-height: 34px;padding-left: 5px !important}
.multi-select .ant-select-selection-item{height: 30px; line-height: 30px; margin-bottom: 5px;border-radius: 50px;}
.multi-select .ant-select-selection-overflow{height: 38px; overflow-y: auto;}
.ant-input-number.cust-input{width: 100%;}
.ant-input-textarea textarea{border-radius: 16px;}
.admin-actions{display: flex;padding: 0;list-style: none;margin-bottom: 12px;}
.admin-actions li{margin-right: 16px;}
.admin-actions li:last-child{margin-right: 0;}
.actions-line{border-bottom: 1px solid; border-color: var(--borderLight);background: var(--bgWhite);padding: 0 18px; height: 44px;}
.k-pager-numbers .k-link.k-state-selected,.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected ,.k-list .k-item.k-state-selected:hover,.k-list-optionlabel.k-state-selected:hover{color: var(--textDark);background-color: var(--bgYellow);}
.k-pager-numbers .k-link{color: var(--textDark);}
 .k-button, .k-textbox, .k-pager-numbers .k-link, .k-pager-numbers .k-link.k-state-selected,.ant-pagination-item,.ant-pagination-item-active,.ant-pagination-item-active a,.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{border-radius: 30px;}
.k-i-sort-asc-sm::before, .k-i-sort-desc-sm::before,.k-i-arrow-60-left::before,.k-i-arrow-60-right::before, .k-i-arrow-end-left::before,.k-i-arrow-end-right::before{color: var(--textDark);}
.k-button.k-primary.excel-btn{background: var(--bgYellow); color: var(--textDark); border-color: var(--bgYellow);padding: 10px 24px;font-weight: 500;text-transform: uppercase;}
.k-button.k-primary{background: var(--bgYellow); color: var(--textDark); border-color: var(--bgYellow);padding: 4px 24px;font-weight: 500;text-transform: uppercase;}
.k-button.k-primary:active, .k-button.k-primary:hover{background: var(--bgDarkYellow); color: var(--textDark); border-color: var(--bgDarkYellow);}
.k-grid thead{background: var(--bgDarkGrey);}
.k-grid-header .k-header{font-weight: 500;}
.k-grouping-row .k-icon.k-i-collapse{width:20px; height: 20px; background-position:-490px -2px;}
.k-grouping-row .k-i-expand.k-icon{width:20px; height: 20px; background-position:-472px -2px;}
.k-i-more-vertical.k-icon{width:20px; height: 20px; background-position:-349px -1px; transform: scale(0.75);}
.k-grouping-row .k-i-collapse::before, .k-grouping-row .k-i-expand::before, .k-i-more-vertical:before{display: none;}
.ant-picker-suffix{color: var(--textWhite30);}
.cust-datepick .ant-picker{width: 200px;}
.cust-datepick  .ant-picker:hover{border-color: var(--bgDarkYellow);}
.cust-datepick  .ant-picker-focused{box-shadow: none;border-color:var(--borderLight);}
.ant-picker.cust-input{width:100% !important}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{background-color: var(--bgDarkYellow); color: var(--textDark);}
.ant-picker-cell-today .ant-picker-cell-inner,.ant-picker-cell-today:hover .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{background-color: var(--bgDarkYellow);color: var(--textDark);}
.main-content{background-color: var(--bgWhite); min-height: calc(100vh - 152px);padding:16px; margin-bottom: 55px; }
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border: none;}
.sidernav li{margin-top: 0 !important}
.sidernav .ant-menu-inline{border-right: 0 !important;}
.sidernav .ant-layout-sider-trigger, .footer-remove .ant-modal-content .ant-modal-footer, .footer-remove .k-widget.k-grid-pager{display: none !important;}
.sidernav .ant-menu-inline .ant-menu-item{padding: 0 16px  !important;height: 50px; line-height: 50px;}
.header-right li {padding: 0 !important;margin: 0 6px;font-size: 16px; color: var(--textWhite30) !important;}
.appmenu-list{display: flex;flex-wrap: wrap;padding: 0;}
.appmenu-list li{width: 16%;text-align: center;list-style: none;padding: 8px;border-right: 1px solid var(--borderGrey);}
.client-names{flex-wrap: wrap;align-items: center;gap: 12px;}
.client-names h4{font-size: 14px;font-weight: 600;flex: 180px;word-break: break-word;margin-bottom: 12px;}
/* border-bottom: 1px solid var(--borderGrey); */
/* .appmenu-list li:not(:nth-child(odd)){border-right:0} */
.appmenu-list li:last-child{border-right: none;border-bottom: 0;}
.appmenu-list li img{height: 40px;display: block;margin: 5px auto 5px;}
.appmenu-list li:hover{background-color: #f9f9f9}
.custom-info .form-bg{background: #fff;padding: 30px 16px;border-radius: 4px;}
.custom-info .ant-tabs-tab-active{ background: #fff;padding: 12px 10px;border-top-right-radius: 4px;border-top-left-radius: 4px;}
.custom-info .ant-tabs-ink-bar, .buysell-toggle .ant-tabs-ink-bar{background:transparent;}
.custom-info .ant-tabs-nav{margin-bottom: 0;}
.custom-info .ant-tabs-tab + .ant-tabs-tab{margin: 0 0 0 26px;}
.custom-info{background: var(--bgWhite);}
.toggle-space{position: relative;}
.toggle-space::before{content: '';position: absolute;width: 1px;height: 27px;right: -17px;background-color: #cecece; margin-left: 10px;}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{border-bottom: 0 !important;}
.ant-tabs-nav{margin-bottom: 0 !important;}
.no-data{background-color: var(--bgBlu);padding: 12px;border-radius: 4px;margin: 30px 0;}
.ant-input:focus, .ant-input-focused, .ant-input:hover{border-color: var(--bgDark);}
.buysell-toggle{border-radius: 20px; background: var(--bgDarkGrey); padding: 8px;display: block;margin-bottom: 16px;width: 367px;}
.buysell-toggle.deductions-toggle{width: 560px;}
.buysell-toggle .ant-radio-button-wrapper{border-radius: 15px;border: none;background: transparent;z-index: 0;color: var(--textYellow);text-transform: uppercase;font-weight: 600;padding: 18px 0; min-width: 175px;line-height: 0;font-size: 14px;text-align: center;}
.buysell-toggle .ant-radio-button{border-radius: 15px;font-size: 12px;}
.buysell-toggle .ant-radio-button-checked{background: var(--bgYellow);border-left: none;}
.buysell-toggle .ant-radio-button-wrapper-checked{color: var(--textDark) !important;}
.buysell-toggle .ant-radio-button-wrapper::before, .crypto-card .ant-card-body::before, .crypto-card .ant-card-body::after{content: none;}
.ant-modal-footer .ant-btn-primary, .primary-btn, .ant-btn.ant-btn-primary,.preview-btn:focus,.primary-btn:focus{background-color: var(--bgYellow); border-color: var(--bgYellow); border-radius: 42px;padding:0 36px;height: 38px; font-weight: 700; transition: 0.3s ease;color: var(--textDark);}
.gridaction-btn{background-color: transparent; border-color: transparent; border-radius: 12px;padding:0 20px;height: 22px; text-transform: capitalize !important;font-weight: 700; transition: 0.3s ease;color: var(--textDark); font-size: 12px !important; line-height: 14px !important;}
.gridaction-btn.primary-btn{background-color: var(--bgYellow); border-color: var(--bgYellow);}
.gridaction-btn.activity-btn, .gridaction-btn.activity-btn:hover, .gridaction-btn.activity-btn:focus, .gridaction-btn.activity-btn:active{background-color: #5623C4 !important; border-color: #5623C4 !important;border-radius: 12px;padding:0 20px;height: 22px; text-transform: capitalize !important;font-weight: 500; transition: 0.3s ease;color: var(--textWhite); font-size: 12px !important; line-height: 14px !important;}
.tox .tox-button {background-color: var(--bgYellow) !important; border-color: var(--bgYellow) !important; border-radius: 42px !important;padding:0 36px !important;height: 38px !important; text-transform: uppercase !important;font-weight: 700 !important; transition: 0.3s ease;color: var(--textDark) !important;}
.tox .tox-button--naked{background-color: transparent !important; border: 0 !important;}
.tox-button.tox-button--secondary{background-color: transparent !important;}
.primary-btn:hover,.primary-btn:active{background-color: var(--bgYellow);border-color: var(--bgYellow);color: var(--textDark);}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    color: rgb(36 53 64); background: var(--bgDarkYellow); border-color: #ffe03a; text-shadow: none;box-shadow: none;
}
.ant-alert.ant-alert-with-description{align-items: center !important;}
.submission-sec{margin: 0 auto;
    width: 500px;}
.cancel-btn{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;}
.cancel-btn:hover{background-color: #f4f4f4 !important;}
.ant-modal-footer .cancel-btn{padding: 0 30px;}
.db-card{border-radius: 30px;background-color: #ebf0f3;padding:30px;margin-bottom: 16px;min-height: 120px;cursor: pointer;}
.db-card:hover{background-color: #e1e8ed;}
.db-card .ant-card-body{padding: 0;}
.enter-val-container{display: flex;align-items: flex-end;}
.enter-val-container .label{margin: 5px 0 5px 12px;display: block;}
.val-swap{background-color: var(--bgDarkGrey);border-top: 1px solid var(--borderLight); border-bottom: 1px solid var(--borderLight);}
.enter-val-container .from-input{border-radius: 30px 0 0 30px;}
.newswap-align{width: 50%;padding-left: 12px;padding-right: 12px}
.newswap-align .enterval-style{width: 100%;max-width: 58%;}
.newswap-align .swap-right{width: 100%;max-width: 51%;}
.enter-val-container .to-input{border-radius: 0 30px 30px 0;}
.case-overflow{width:295px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.gridLink{max-width: 100%; display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer;color: var(--textLink);}
.loader{position: absolute;top: 0; right: 0; bottom: 0; left: 0;z-index: 9;display: flex; align-items: center;background-color: rgba(255,255,255,0.85);justify-content: center;}
.input-label.ant-row{display: block !important; margin-bottom: 20px;}
.input-label.ant-form-item-has-error{margin-bottom: 0 !important;}
.mce-content-body.mce-content-readonly{cursor: not-allowed !important;}
/* .input-label .ant-form-item-explain-error div[role="alert"]{margin-left: 16px;} */
.ant-form-item-label > label::after{content: none;}
.cust-input.ant-select-single .ant-select-selector .ant-select-selection-item, .cust-input, .cust-input .ant-select-selection-placeholder{line-height: 38px !important;width: 100%;}
.ant-select-open .ant-select-selector{box-shadow: none !important;}
.cust-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input, .cust-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 38px;}
.cust-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 38px; background-color: transparent !important; line-height: 38px;border-radius: 30px;}
.cust-input.ant-select-focused, .cust-input.ant-select-open{outline: 0;}
/* .cust-input .ant-select-arrow{background: var(--lightThemeIcons) no-repeat; display: inline-block; vertical-align: middle;width: 20px; height: 20px;background-position: -352px -5px;} */
/* .cust-input .anticon-down{display: none;} */
.cust-input .ant-input-number-handler-wrap{right: 10px;}
.page-title{font-size: 16px !important;color: var(--textWhite30);font-weight: 500;margin-bottom: 12px !important;}
.page-subtitle{font-size: 16px;color: var(--textWhite30);}
.k-master-row a{color: var(--textLink);}
.required::after{display: inline-block;margin-left: 6px;color: #ff4d4f;font-size: 16px;line-height: 1;content: '*';}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{content: none;}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{display: inline-block;margin-right: 4px;color: #ff4d4f;font-size: 14px;font-family: SimSun, sans-serif;line-height: 1;content: '*';}
.ant-form-item-label{margin-left: 12px;}
.ant-select.cust-input{padding: 0 !important;border: 0;}
.swap-right{position: relative;}
.swap-right .ant-spin{position: absolute;left: 16px;top: 43px;z-index: 99;}
.transfer-right{position: relative;}
.transfer-right .ant-spin{position: absolute;left: 16px;top: 34px;z-index: 99;}
/*--Customers--*/
.coin{width: 43px; height: 43px;background: var(--cryptoIcons) no-repeat; display: inline-block; border-radius: 6px;}
.coin.XRP{background-position: -2px -60px;}
.coin.USDT{background-position: -596px -1px;}
.coin.DAT{background-position: -323px -1px;}
.coin.BTC{background-position: -108px -1px;}
.coin.ETH{background-position: -55px -1px;}
.coin.OXBTC{background-position: -596px -180px;}
.coin.BNB{background-position: -650px -1px;}
.coin.ADA{background-position: -702px -1px;}
.coin.USDC{background-position: -649px -60px;}
.coin.DOGE{background-position: -756px -1px;}
.coin.DOT{background-position: -55px -60px;}
.coin.USD{background-position: -216px -1px;}
.coin.BUSD{background-position: -378px -60px;}
.coin.UNI{background-position: -702px -60px;}
.coin.LTC{background-position: -108px -60px;}
.coin.SOL{background-position: -596px -60px;}
.coin.LINK{background-position:-544px -60px;}
.coin.MATIC{background-position:-488px -60px;}
.coin.THETA{background-position:-435px -60px;}
.coin.DAI{background-position:-2px -120px;}
.coin.VET{background-position:-323px -60px;}
.coin.FIL{background-position:-216px -60px;}
.coin.ALGO{background-position:-435px -120px;}
.coin.CDAI{background-position:-2px -180px;}
.coin.LUNA{background-position:-55px -180px;}
.coin.AAVE{background-position:-216px -120px;}
.coin.SHIB{background-position:-164px -120px;}
.coin.TRX{background-position:-163px -60px;}
.coin.XMR{background-position:-56px -120px;}
.coin.OKB{background-position:-269px -120px;}
.coin.EOS{background-position:-109px -120px;}
.coin.CETH{background-position:-434px -180px;}
.coin.CAKE{background-position:-598px -120px;}
.coin.LEO{background-position:-164px -183px;}
.coin.ATOM{background-position:-488px -120px;}
.coin.MKR{background-position:-702px -116px}
.coin.BSV{background-position:-544px -120px;}
.coin.COMP{background-position:-164px -303px;}
.coin.NEO{background-position:-323px -120px;}
.coin.AVAX{background-position:-108px -183px;}
.coin.MIOTA{background-position:-756px -60px;}
.coin.HBAR{background-position:-378px -180px;}
.coin.KSM{background-position:-378px -120px;}
.coin.HT{background-position:-216px -183px;}
.coin.DASH{background-position:-323px -303px;}
.coin.BTT{background-position:-268px -183px;}
.coin.TUSD{background-position:-863px -303px;}
.coin.NEXO{background-position:-811px -120px;}
.coin.HOT{background-position:-863px -240px;}
.coin.PAX{background-position:-810px -240px;}
.coin.RUNE{background-position:-756px -120px;}
.coin.ZIL{background-position:-920px -3px;}
.coin.ARRR{background-position:-543px -180px;}
.coin.BAL{background-position:-325px -240px;}
.coin.GAS{background-position:-323px -120px;}
.coin.GUSD{background-position:-864px -3px;}
.coin.BAND{background-position:-378px -240px;}
.coin.PAc{background-position:-543px -180px;}
.coin.DOCK{background-position:-378px -303px;}
.coin.GRIN{background-position:-864px -120px;}
.coin.GTO{background-position:-651px -180px;}
.coin.SAI{background-position:-268px -305px;}
.coin.STORM{background-position:-542px -305px;}
.coin.AEON{background-position:-757px -180px;}
.coin.GSC{background-position:-864px -60px;}
.coin.COLX{background-position:-108px -303px;}
.coin.BTDX{background-position:-757px -240px;}
.coin.DROP{background-position:-434px -303px;}
.coin.BSD{background-position:-598px -240px;}
.coin.ARG{background-position:-53px -240px;}
.coin.STPL{background-position:-216px -305px;}
.coin.DTH{background-position:-433px -305px;}
.coin.TBX{background-position:-812px -305px;}
.coin.SPARTA{background-position:-2px -240px;}
.coin.POW{background-position:-811px -180px;}
.coin.CMM{background-position:-57px -305px;}
.coin.ENTRP{background-position:-599px -305px;}
.coin.AMP{background-position:-268px -60px;}
.coin.TFUEL{background-position:-652px -120px;}
.coin.OXY{background-position:-487px -240px;}
.coin.GBP{background-position:-433px -1px;}
.coin.EUR{background-position:-378px -1px;}
.wallet-item{border: 1px solid var(--borderLight);padding: 16px 16px 8px;border-radius: 12px; margin-bottom: 12px;}
.user-circle{width: 120px; height: 120px; border-radius: 50%;margin-bottom: 12px;}
.view-left .user{margin: 15px 0 20px 0;}
.kpi-item{display: flex; margin-bottom: 30px; padding-left: 16px; padding-right: 16px;}
.kpi-item span + div{flex: 1;}
.kpi-item .icon{margin: 10px 16px 0 0;}
.view-right .ant-col.item{border-bottom: 1px solid var(--borderLight);}
.detailview{border: 1px solid var(--borderLight);}
.cust-inputlabel-style .ant-form-item-label > label{height: 24px;}
/*--Banks--*/

.bank-view .kpi-List{background-color: var(--bgDarkGrey);border-radius: 30px;padding: 34px 34px 0;}
.bankview-right{padding: 34px 34px 0 !important;background-color: var(--bgDarkGrey);border-radius: 30px;}
.bankview-right .kpi-val{padding-bottom: 34px;}
.bankview-right .kpi-val:not(:last-child){border-bottom: 1px solid var(--borderLight);margin-bottom: 16px;padding-bottom: 16px;}
.kpi-List .ant-col{display: flex; margin-bottom: 34px;}
.kpi-List .icon{margin: 6px 6px 0 0;}
.kpi-List .icon + div{flex: 1;}
.kpi-List .kpi-label{font-size: 12px;color: var(--textWhite50);}
.kpi-List .kpi-val{font-size: 14px;font-weight: 500;color: var(--textDark);word-break: break-word;margin-right: 12px;}
.kpi-item .kpi-val{margin-left: 0;}
.icon.md.cash{background-position: -267px -30px;}
.icon.md.Commissions, .icon.md.commission{background-position: -686px -31px}
.icon.md.moretools{background-position: -61px -30px;}
.icon.md.difference{background-position: -717px -31px;}
.icon.md.bank{background-position: -296px -30px;}
.icon.md.location{background-position:-325px -30px;}
.icon.md.network{background-position:-352px -30px;}
.icon.md.networkcode{background-position:-381px -30px;}
.icon.md.id{background-position:-410px -30px;}
.icon.md.routing{background-position:-381px -30px;}
.icon.md.status{background-position:-438px -31px;}
.icon.md.StateChange{background-position:-438px -31px;}
.icon.md.create-case{background-position:-800px -30px}
.icon.md.card-status{background-position: -791px -58px}
.icon.md.fill-gas{background-position: -660px -2px}
.icon.md.reset-sweep{background-position: -686px -2px}
.icon.md.Upload{background-position:-657px -31px;}
.icon.md.Approved{background-position:-576px -31px;}
.icon.md.Cancelled{background-position:-545px -31px;}
.icon.md.USD{background-position:-85px -60px;}
.icon.md.EUR{background-position:-144px -60px;}
.icon.md.GBP{background-position:-172px -60px;}
.icon.md.user{background-position:-492px -31px;}
.icon.md.userby{background-position:-517px -61px;}
.icon.md.acnum{background-position:-115px -60px;}
.icon.md.wallet{background-position:-201px -60px;}
.icon.md.file{background-position:-659px -60px;}
.icon.md.text{background-position:-362px -60px;}
.icon.md.card{background-position:-57px -60px;}
.icon.md.date{background-position:-28px -59px;}
.icon.md.left-o-arw{background-position:-229px -59px;}
.icon.md.right-o-arw{background-position:-255px -59px;}
.icon.md.close-pop{background-position: -168px 0px;transform: scale(0.9);}
.icon.xl.datepicker{background-position:-350px -198px}
.icon.xl.phone{background-position:-264px -200px;}
.icon.xl.email{background-position:-180px -202px;}
.icon.xl.doc{background-position:-428px -196px}
.icon.xl.card{background-position:-390px -198px;}
.icon.xl.fromWallet{background-position:-5px -199px;}
.icon.xl.toWallet{background-position:-48px -199px;}
.icon.xl.buysell{background-position:-89px -199px;}
.icon.xl.emailverified{background-position: -194px -264px;}
.icon.xl.phoneverified{background-position: -237px -262px;}
.icon.xl.sbstafficon{background-position: -280px -262px;}
.icon.md.freeze { background-position: -884px -29px;}
.icon.md.disable { background-position: -119px -29px;}
/* .icon.md.excelexport{background-position:-238px -30px} */
.icon.md.export-excel{background-position:-238px -30px}
.custom-checkbox input[type=checkbox]{display: block;position: absolute;zoom: 1.5;margin: 0;opacity: 0; cursor: pointer;}
.custom-checkbox input[type=checkbox]+span,.ant-checkbox .ant-checkbox-inner{ width: 20px; height: 20px;cursor: pointer;background-position:-185px -30px;}
.custom-checkbox input[type=checkbox]:checked+span{ width: 20px !important; height: 20px !important;cursor: pointer;background-position:-152px -30px !important;}
.custom-checkbox input[type=checkbox]:disabled,.custom-checkbox input[type=checkbox]:disabled+span{cursor: not-allowed;}
.custom-checkbox{width: 20px; display: flex;align-items: center;}
.custom-checkbox .ant-form-item-control-input{min-height: auto;}
.custom-checkbox.action-check{width: 100%;}
.excel-btn{float: right;}
.k-grid{clear: both;}
.ant-message{  bottom: 15px !important; top: auto;}
.custom-msg .ant-message-notice-content{background:#333; color: var(--textWhite); padding: 20px 40px; min-width: 200px;}
.primary-btn.search{padding: 0 25px !important;}
/*--K-Filter--*/
.k-filter-menu span.k-filter-and{width: 93% !important;}
.k-dropdown-wrap, .k-state-default,.ant-pagination-options .ant-select-selector{border-radius: 16px !important; background: transparent !important;}
.custom-radio{background: #f2f2f2; padding: 6px; border-radius: 8px;}
.custom-radio .ant-radio-button-wrapper{padding: 0 30px !important;  font-size: 16px; line-height: 36px; color: var(--textDark); background: transparent !important; height: 36px !important;border:0 !important;}
.custom-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: var(--bgYellow) !important; color: var(--textDark); border-color: var(--bgYellow) !important; font-weight: 400 !important; border-radius: 8px; z-index: 0;
}
.user-name{text-overflow: ellipsis; overflow: hidden; white-space: nowrap;padding: 0 10px;}
.custom-radio .ant-radio-button-wrapper:not(:first-child)::before{content: none !important;}
.custom-alert{width:auto;
    position: fixed;
    z-index: 1;
    bottom: 15px;
    left: 50%;
    margin: 0 auto;transform: translate(-50%);}
.custom-alert .ant-alert{background:#333; color: var(--textWhite); padding: 20px 40px;border: 1px solid #333}
.custom-alert .ant-alert-message {color: var(--textWhite) !important;font-size: 14px !important;font-weight: 300;}
.custom-alert .ant-alert-info .ant-alert-icon{color: var(--textWhite) !important;}
.search-btn{border-radius: 8px !important;}
.form-bg .ant-row{margin-bottom: 0;}
footer{position: absolute; left: 0px; bottom: 0; right: 0;}
.ant-tabs-nav-list{padding: 4px 0;}
.ant-tabs-nav-wrap{border: 1px solid #00000014;border-bottom: 0;line-height: 0.9; padding: 0 10px;  background: #fafafa !important;}
.ant-tabs-tab.ant-tabs-tab-active{background: var(--bgYellow);}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,  .ant-tabs-tab:hover,.ant-tabs-tab:active{color: var(--textDark);}
.ant-tabs-tab{padding: 10px 20px !important; border-radius: 8px !important;}
.ant-breadcrumb .ant-breadcrumb-link:nth-child(2) a{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 200px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.ant-breadcrumb-link:last-child{display: none !important;}
.ant-tabs-tab + .ant-tabs-tab{margin: 0;}
.ant-tabs-ink-bar{display: none;}
.ant-input-disabled,.ant-select-disabled, .ant-input-number-disabled{color: var(--textDark) !important;background-color: #f7f7f7 !important;cursor: not-allowed;}
.ant-select-disabled .ant-select-selection-item, .ant-picker-input > input[disabled]{color: var(--textDark) !important;}
.k-grid-header, .k-grid-footer{padding-right: 0px;}
.tox-tinymce--disabled{cursor:not-allowed;}
.ant-modal-close-x, .ant-breadcrumb > span:last-child a{cursor: default;}.ant-alert-info
.ant-modal-close-x .icon{cursor: pointer;}
.ant-table-thead > tr > th, .roles-custom tr th{background: var(--bgDarkGrey) !important;border-right: 1px solid #dcdcdc !important; }
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td, .roles-custom tr th, .roles-custom td{padding: 8px 16px !important;}
.ant-table-pagination.ant-pagination{display: none !important;}
.ant-tooltip-inner{text-transform: capitalize;}
.trans-collapse .ant-collapse-header .icon.downangle, .ant-collapse-header .icon.upangle{vertical-align: text-bottom !important;}
.trans-collapse .ant-collapse-header .icon{position: relative;margin-right: 24px !important;}
.trans-collapse .ant-collapse-header .icon::after{content: '';position: absolute; right: -10px; top: 0; border-right: 1px solid var(--borderLight);height: 20px;}
.trans-collapse .ant-collapse-item.ant-collapse-item-active .downangle{background-position: -329px 0;}
.pr-24{padding-right: 24px !important;}

/* --- Documents ---*/
.accordian, .accordian .ant-collapse-header{border-radius: 20px !important;}
.accordian .ant-collapse-item:last-child > .ant-collapse-content, .accordian .ant-collapse-item{border-radius: 0 0 20px 20px !important;border-color: var(--borderLight);}
.accordian .ant-collapse-header{font-size: 18px;font-weight: 600;background-color: #ECF1F4;padding: 16px 20px 16px 24px !important}
.accordian .ant-collapse-header .icon.downangle{position: relative;margin-right: 34px !important;}
.accordian .ant-collapse-header .icon.downangle::after{content: '';position: absolute; right: -16px; top: 0; border-right: 1px solid var(--borderLight);height: 20px;}
.accordian .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{border-radius: 20px 20px 0 0 !important;}
.accordian .ant-collapse-arrow{vertical-align: text-bottom !important;}
.accordian .ant-collapse-item.ant-collapse-item-active .downangle{background-position: -329px 0;}
.accordian .ant-collapse-content-box{padding: 20px;}
.upload{border-radius: 30px !important; background-color: #9797AA;}
.upload .ant-upload{padding: 36px 0 !important;}
.upload .ant-upload-text{font-weight: 600;color: #0E0E2C !important;}
.docfile{background-color: #ECF1F4;border-radius: 20px;padding: 16px;display: flex;align-items: center;margin-right: 10px;}
.docdetails{flex: 1}
.docdetails .docname{font-weight: 700;color: #0E0E2C !important;text-transform: uppercase; line-height: 16px;max-width: 150px !important;}
.docfile-container{display: flex; flex-wrap: wrap; margin-top: 10px;}
.staus-lbl{display: inline-block;color: var(--textPureWhite);background-color: var(--bgGreen);font-size: 12px;padding: 2px 8px;border-radius: 4px;font-weight: 500;}
.approved-lbl{background-color: var(--bgGreen);}
.rejected-lbl{background-color: #ff0000;}
.submitted-lbl{background-color: #0038FF;}
.requested-lbl{background-color: #e0bb02;}
.reply-container{display: flex;margin-bottom: 24px;}
.reply-container .user-shortname{width: 46px; height: 46px; border-radius: 50%;background-color: #64869b;margin-right: 16px;color: #FFFFFF;font-size: 18px;text-align: center;line-height: 46px;font-weight: 500;text-transform: uppercase;}
.reply-body{flex: 1;}
.reply-txt{font-size: 16px;color: var(--textWhite50);margin-bottom: 10px;word-break: break-all;}
.reply-date{font-size: 12px; color: var(--textWhite50);}
.reply-username{font-size: 14px; color: var(--textDark);font-weight: 600;display: inline-block;margin-right: 10px;}
.send-circle{background: var(--bgYellow); width: 40px;height: 40px; line-height: 40px; text-align: center;border-radius: 50%;}
.chat-send{display: flex;border: 1px solid var(--borderLight); padding:12px; border-radius: 20px; justify-content: space-between; align-items: center;margin-bottom: 16px;}
.chat-send input{border:0; flex: 1;font-size: 16px;}
.chat-send input:focus{outline: none;}
.docmodal-footer{border-top: 1px solid var(--borderLight);padding: 20px 24px 0; margin: 34px -24px 0;}
.preview-file{text-align: center;}
.react-pdf__Page__canvas{margin: auto;}
.phone-vth-code{display: flex;align-items: flex-start;}
.phone-vth-code .ant-select-selector{border-right: 0 !important;border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;}
.custom-add-select{border-bottom-right-radius: 0;border-top-right-radius: 0;}
.otp-verify{position: relative;margin-left: 0 !important;}
.otp-verify button{
    color: var(--textYellow) !important;
    font-weight: 500;
    padding: 0 6px 0 0;
}
.otp-verify .ant-form-item-label{
    width: auto;
    position: absolute;
    right: 4px;
    top: 10px;
    z-index: 1;
}
.otp-verify .ant-form-item-label > label::after{
    content: none !important;
}
.input-label.otp-verify .ant-form-item-control{margin-top: 4px;}
.get-code{
    width: 100%;
    text-align: center;
    height: 50px;
    border: 1px solid var(--borderLight);
    color: var(--textWhite30);
    font-size: 16px;
    padding: 0 16px !important;
}
.hy-align{margin:auto 0; display: flex;align-items: center;}
.hy-align .ant-btn-loading::before{background-color: transparent !important;}
.new-add{border: 1px solid #899097; padding: 14px;border-bottom-right-radius: 30px; border-top-right-radius: 30px; border-left: 0;}
.new-add .ant-btn:hover, .new-add .ant-btn:focus, .new-add .ant-btn:active{border-color: transparent !important;outline: none !important;background-color: transparent;}
.new-add .loading-btn + button{position:absolute; right:142px !important;}




/* .phone-vth-code #advanced_search_phoneNumber{border-top-left-radius: 0; border-bottom-left-radius: 0;margin-top: 32px;} */
.phone-vth-code #advanced_search_phoneNumber{border-top-left-radius: 0; border-bottom-left-radius: 0;}
.commission-grid .k-grid-header table thead tr:first-child th{text-align: center;}
.commission-grid .k-grid-header table thead tr:first-child th:nth-child(1), .commission-grid .k-grid-header table thead tr:first-child th:nth-child(2), .commission-grid .k-grid-header table thead tr:first-child th:nth-child(3){text-align: left;}
.adjust + div .commission-grid .k-grid-header table thead tr:first-child th:nth-child(5), .adjust + div .commission-grid .k-grid-header table thead tr:first-child th:nth-child(4){text-align: left;}
.commission-grid .k-master-row td:nth-child(4), .commission-grid .k-master-row td:nth-child(2){text-transform: capitalize;}
.documentmodal-width .preview-pdf-page{border: 1px solid #000000;margin-bottom: 10px;}
.commission-grid .k-grid-header table thead tr:first-child th:last-child{text-align: left;}

/*--Dashboard--*/
.db-container{background-color: #EBF0F3;padding: 0;}
.db-kpi{background-color: #FFFFFF;border-radius: 16px;padding: 12px 16px;margin-bottom: 8px;min-height: 105px;}
.db-kpi-label{font-size: 12px;color: var(--textSecondary);display: block;}
.db-kpi-val{font-size: 18px;color: var(--textDark);font-weight: 600;line-height: 34px; display: block;}
.db-kpi-val1{font-size: 14px;color: var(--textDark);font-weight: 500;line-height: 34px; display: block;}
.db-kpi .badge{background-color: #F0F0F0;padding: 3px 10px;font-size: 12px;font-weight: 500;color: #D13F00;border-radius: 16px;vertical-align: text-bottom;display: inline-block;}
.db-kpi .icon-bg{width: 48px;height: 48px;border-radius: 8px;background-color: #FFF7E9;margin-right: 16px;text-align: center; line-height: 48px;}
.db-kpi.vthicon{display: flex;align-items: center;min-height: 105px;}
.graph{border-radius: 20px;margin-bottom: 16px;background-color: var(--bgGloom);border-color: var(--borderGrey);}
.graph .graph-title{border-bottom: 1px solid var(--borderGrey);padding: 16px;}
.graph .graph-body{min-height: 430px;padding: 8px;}
.wmy-graph .ant-radio-button-wrapper{background-color: var(--bgWhite) !important;border-radius: 16px;margin-right: 8px;font-weight: 600;color: var(--textSecondary);}
.wmy-graph .ant-radio-button-checked{background-color: var(--bgYellow);border-color: var(--bgYellow) !important;border-radius: 16px;}
.wmy-graph .ant-radio-button-wrapper:not(:first-child)::before{content: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{background: none;}
.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){background: none;color: var(--textDark) !important;}
#graph-container, #container{width: 100%; height: 100%;}
.side-drawer-header{display: flex;justify-content: space-between;align-items: center;}
.coin-info{padding: 12px 0;border-top: 1px solid var(--borderGrey);display: flex;justify-content: space-between;}
.coin-info .hisstate{font-size: 14px;color: var(--textWhite30);font-weight: 600;display: block;line-height: normal;}
.coin-info .hislbl{font-size: 13px;color: var(--textWhite50);font-weight: 300;}
.side-drawer .ant-drawer-header{border: none;}
.side-drawer .ant-drawer-body{padding: 0 20px 20px 20px;}
.cust-input.text-area{line-height: 24px !important;padding-top: 10px !important;}
.chart-loader{display: flex;justify-content: center;height: 400px; align-items: center;}
/* changes */
/* --Payments --*/
.pay-grid{overflow-x: auto;width: 100%;}
.w-100px{width: 100px;}
.w-150px{width: 150px;}
.w-200px{width: 200px;}
.w-350px{width: 350px;}
.pay-grid thead th{background-color: var(--bgDarkGrey);padding: 8px; color: var(--textWhite30);font-weight: 400;text-align: left;}
.pay-grid thead th:last-child{width: 250px;}
.pay-grid tbody td{padding: 8px; color: var(--textWhite30);font-weight: 400;border: 1px solid var(--borderGrey);}
.ant-popover-inner{background-color: var(--bgGloom); border-radius: 10px;}
.more-popover .lbl{font-size: 12px; color: var(--textWhite50);font-weight: 100;}
.more-popover .val{font-size: 14px; color: var(--textWhite30);display: block;margin-bottom: 16px; line-height: normal;}
.ant-popover-arrow{border-color: var(--bgGloom) !important;}
.kyc-docs a{display: inline-block;padding: 3px 1px;border:1px solid var(--borderGrey);border-radius: 16px;margin: 4px 4px 0 0;}
.file-label{border: 1px solid var(--borderLight);border-radius: 6px;padding: 2px 8px;color: var(--textLink);cursor: pointer;background-color: var(--bgDarkGrey);}

/* --Cases-- */
.case-ribbon{background-color: var(--bgDarkGrey);padding: 16px;border-radius: 10px;margin-bottom: 24px;}
.ribbon-item{display: flex; border-right: 1px solid var(--borderLight); align-items: center;}
.case-ribbon .ant-col:last-child .ribbon-item {border: none;}
.req-doc-items{display: flex;justify-content: space-between;border: 1px solid var(--borderLight);padding: 8px;border-radius: 8px;cursor: pointer;
margin-bottom: 8px;}
.imp-alert{border-radius: 10px; position: relative;margin-bottom: 20px;padding-bottom: 30px !important;}
.imp-alert .ant-form-item-explain-error{position: absolute;margin-left:10px;margin-top: 10px; left: -12px; bottom: -76px;font-weight: normal;}
.report-styles{height: 800px}
.dashboard-styles{height: 920px}
.icon.md.Type{background-position:-487px -60px;}
.icon.md.Email{background-position:-334px -59px}
.icon.md.Name{background-position:-491px -31px;}
.icon.md.Currency{background-position:-85px -60px;}
.icon.md.Bank.Name{background-position:-296px -30px}
.icon.md.Date{background-position:-85px -29px;}
.icon.md.Amount{background-position:-267px -30px;}
.icon.md.description, .icon.md.Alert.Description{background-position:-362px -59px;}
.icon.md.Alert.Number{background-position:-115px -60px;}
.icon.md.Alert.Type{background-position:-746px -31px;}
.icon.md.Alert.Title{background-position:-772px -30px;}
.icon.md.Transaction.Id{background-position:-381px -30px;}
.icon.md.Features{background-position:-1px -60px;}
.icon.lg.new-deposit{background-position:-467px -200px;}
.icon.lg.companywallets{background-position: -881px -90px;}
.icon.lg.transfer{background-position: -914px -90px;}
.icon.lg.Cards{background-position: -1086px -87px;}

/*--Deposit case--*/
.audit-trail-msg{padding: 6px 10px 10px !important;border-radius: 8px;border: none;}
/* .kyc-docs a{display: inline-block;padding: 3px 12px;border:1px solid var(--borderGrey);border-radius: 16px;margin-right: 4px;} */
.track-info{display: flex;}
.track-info li{margin-left: 40px !important;font-size: 12px;color: var(--textSecondary);padding: 0 !important;}
.track-info li:first-child{margin-left: 16px !important;}
/* .file-label{border: 1px solid var(--borderLight);border-radius: 6px;padding: 2px 8px;color: var(--textLink);cursor: pointer;background-color: var(--bgDarkGrey);} */
.crypto-list .coin-info:first-child{border: none;}
.pwd-popup.pop-cancel{vertical-align: middle;margin: 10px auto !important;height: 38px !important;text-align: center !important;box-shadow: none;}
.app-update{width: 100% !important; margin-bottom: 16px;}
.text-nowrap{white-space: nowrap;width: auto;}
.badge-style{ background: var(--bgYellow);padding: 8px 20px;border-radius: 15px;width: fit-content;font-size: 12;font-weight: 600;min-width: 170px;text-align: center;}
.referralparty-icon{position: absolute;top: 31px;right: 16px;}
.pr-10{padding-right: 10px;}
.applicant-content{border:1px solid #CECECE;border-radius:8px;padding: 20px;margin-bottom:30px}
.identity-image .ant-image{height: 250px; overflow: hidden;width: 237px;border-radius: 8px;}
.identity-image .ant-image-img{width: 100%;height: 100%;object-fit: cover; border-radius: 8px;}
.idendity-imageinfo{display: flex;align-items: center;gap: 20px;flex-wrap: wrap;}
.kyckyb-form{padding: 15px;border-left: 1px solid #00000014; border-right: 1px solid #00000014; border-bottom: 1px solid #00000014;}
.mt20{margin-top: 20px;}
.poa-accordian .ant-collapse-header{background-color: #fafafa;font-size: 16px;font-weight: 600; color: #000 !important;border-top-left-radius: 2px !important;border-top-right-radius: 2px !important;}
.poa-accordian .ant-collapse-item.ant-collapse-item-active .ant-collapse-header{border-top-left-radius: 2px !important;border-top-right-radius: 2px !important;border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;}
.poa-accordian .ant-collapse-item .ant-collapse-header{border-bottom-left-radius: 2px !important;border-bottom-right-radius: 2px !important;}
.poa-table thead tr th{background-color: #ecf1f4;padding: 8px 16px;border: 1px solid rgba(0, 0, 0, 0.08);}
.poa-table tbody td{padding: 8px 16px;width: 50%;border: 1px solid rgba(0, 0, 0, 0.08);}
.poa-table.control-ownership tbody td{width: auto !important;}
.poa-table{margin-top: 10px;}
.poa-table .status-dot,.status-dot{height: 10px;width: 10px;border-radius: 50%;display: inline-block;}
.poatable-feilds{display: flex;gap: 6px;}
.status-dot.green{background-color: #13BA99;}
.status-dot.red{background-color: rgb(226, 14, 14);}
.poa-accordian .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{display: none;}
.icon.lg.check-whitecircle { background-position: -982px -26px;}
.wrap-auto{flex-wrap: wrap;}
.gap-16{gap: 16px;}
.pdf-document{background-color: #fff;height: 250px; width: 237px; border-radius: 8px;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);display: flex;align-items: center;justify-content: center;}
.icon.pdfdocument { background-position: -324px -247px;    height: 52px; width: 52px;}
.mt10{margin-top: 10px;}
.icon.lg.deductions{background-position: -655px -155px;}
.ant-menu-item-selected .icon.lg.deductions{background-position: -655px -188px;}
.client-names p{color: #000; font-size: 14px;}
.company-card{border: 1px solid #ccc;padding: 8px;height: fit-content;border-radius: 6px;position: relative;}
.company-structure{gap: 20px;}
.approved.badge{padding:2px 6px;background-color: #44B291;border-radius: 4px;color: #fff;}
.children-content .company-card.child-card:not(:first-child){margin-top: 8px;}
.children-content .company-card.child-card:not(:first-child)::before {
    content: "";
    width: 11px;
    height: 112px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    position: absolute;
    left: -11px;
    top: -112px;
    bottom: 0;
    margin: auto;
}
.parent-card::after{
    content: "";
    width: 22px;
    height: 0.5px;
    background-color: #ccc;
    position: absolute;
    right: -22px;
    top: 0;
    bottom: 0;
    margin: auto;
    
}
/* .company-card.child-card:before {    content: "";    width: 11px;    height: 112px;    border-left: 1px solid #ccc;    border-bottom: 1px solid #ccc;    position: absolute;    left: -11px;    top: -112px;    bottom: 0;    margin: auto;} */
/* .parent-card::after{    content: "";    width: 22px;    height: 0.5px;    background-color: #ccc;    position: absolute;    right: -22px;    top: 0;    bottom: 0;    margin: auto;   } */
.parent-card{background-color: rgb(214, 255, 214);border-color: rgb(113, 255, 113);}
.icon.user{width: 20px;    height: 20px;    background-position: -283px -327px;margin-right: 6px;}
.icon.bank-icon{width: 20px;    height: 20px;    background-position:-312px -327px;margin-right: 6px;}
@media (min-width: 320px){
    .val-swap{padding: 5px 16px;}
}
/* @media (max-width: 575.98px) {

 } */
 @media (max-width: 360px){
    .cusror-non{display: none;}
    }
@media (min-width: 768px){
    .company-card{min-width: 304px;}
    .cust-footer{margin-right: 16px;}
    .box.mb-flex{display: flex !important;}
    .tlv-header{display: flex;height: 65px;align-items: center; justify-content: space-between;padding: 0 20px !important;border-bottom: 1px solid #e2e2e2; }
    .sidernav{background: var(--bgWhite) !important;margin-top: 65px !important;height: calc(100vh - 65px);box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);overflow-x: hidden !important;}
    .tlv-header ul {
        /* background-color: transparent; */
        border-bottom: 0;line-height: normal}
    .transaction-text{display: flex; justify-content: space-between; align-items: center;}
    .tlv-header li::after, .tlv-header li:hover:after { border: none !important;}
    .sidernav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: transparent;}
    .sidebar-open .ant-layout{margin-left: 110px !important;}
    .sidebar-close .ant-layout{margin-left: 33px;}
    .sidebar-close  .sidernav{width: 65px !important;max-width: 65px !important;min-width: 65px !important}
    .tran-select{display: flex;}
    .side-drawer .ant-drawer-content-wrapper{width: 510px !important;}
    .footer-links{display: flex; justify-content: space-between; align-items: center; border-top: 1px solid var(--borderLight); background-color: var(--bgWhite);}
    .profile-block{display: block;}
    .profile-label{width: 100px;}
    .secureDropdown .ant-dropdown-menu{min-width: 520px;max-width: 520px;}
    .appmenu-list li{width: 32%;}
    .secureDropdown ul.drpdwn-list .userdrp {font-size: 14px;padding: 0 30px !important;transition: 0.3s ease;list-style: none;}
}
@media (max-width: 767px) {
    .identity-image .ant-image{width: 100%;}
    .poa-accordian .ant-collapse-item .ant-collapse-header{padding-right: 46px;}
    .text-break .ant-modal-title{padding-right: 30px;}
    /* .report-styles{height: 313px} */
    .report_container{margin: -16px  !important;}
    .report-styles iframe {
        border: none !important;
    }
    .user-tablist .ant-tabs-nav-list, .user-tablist .ant-tabs-tab, .user-tablist .ant-tabs-nav .ant-tabs-nav-wrap{display: block !important;}
    .profile-tabs{padding: 0 !important;}
    .profile-value{width: 190px;word-break: break-all;}
    .profile-block, .profileinfo .profile-block{display:  block !important;}
    .tlv-header{display: flex;padding: 0 16px!important; justify-content: space-between; align-items: center; line-height: normal;border-bottom: 1px solid #e2e2e2;}
    .site-layout-background .table{width: 100%;min-width: 100%;overflow-x: auto;table-layout: fixed;}
    .sidernav{min-height: 100vh; z-index:1;    margin-top: 108px !important;
        height: calc(100vh - 65px);
        box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
        overflow-x: hidden !important;}
    .sidernav ul{min-height: 100vh;}
    .sidebar-close .sidernav{left:-200px !important}
    .tran-select{margin: 25px 0;}
    .ant-picker{width: 100%;}
    .tran-select .cust-datepick{margin-right: 0 !important; margin-bottom: 8px;}
    .k-grouping-header::before{height: 0 !important;}
    .tlv-header .ant-menu-horizontal{border-bottom:0}
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){z-index: 0 !important;}
    .ant-modal-content{margin: 0 10px 250px;}
    .site-layout-background .k-widget.k-grid{z-index: 0;}
    .refer-code{right: 30px !important;top: 52px !important;}
    .record-history{width: auto !important;height: auto !important;}
    .record-history .icon.md{width: 32px !important; margin-top:4px}
    .buysell-toggle .ant-radio-button-wrapper{width: 100% !important;}
    .value-field{margin-top: 40px !important;}
    .buysell-toggle{width:auto !important}
    .primary-btn{display: block; width: 100%; margin: 0 !important;}
    .primary-btn.cancel-btn{margin: 10px 0   !important;}
    .enter-val-container .from-input,.enter-val-container .to-input{min-width: 130px!important}
    .code-sent-tool-tip{display: flex;}
    .new-add.phn-code .loading-btn + button{right: 7px !important;}


    .pay-list{display: flex;justify-content: space-between;align-items: center;}
    .customer-table-header .k-grid-header-sticky div div{right:80px !important;}
    .transaction-table-header .k-grid-header-sticky div div{right:112px !important;}
    .roles-custom td{padding: 8px 10px !important;}
    .checklist-custom-width{width: 70px !important;}
    .Company-custom-table .ant-table-cell{width:250px;}
    .profile-label{width: 150px;}.tlv-header > div{display: flex;align-items: center;}
    .mobile-ml-8{margin-left: 0;}
    .business-check{top: 26px !important;right: 58px !important;}
    .business-edit{top: 43px !important;}
    .refer-code.cust-success.custom-success{right: 56px !important;  top: 27px !important;}
    .tier-section{overflow: auto;}
    .appmenu-list li{width: 100%;border-right: 0;}
    .secureDropdown.appDropdown .ant-dropdown-menu{min-width: 300px;max-width: 300px;}
    .text-align-inside{text-align: center;}
    .referralparty-icon{position: absolute;top: 38px;right: 16px;}
    .sm-scroll{overflow: auto;}
    .company-structure{min-width: 1200px;}
}
@media (max-width: 991px) {
.arrow-filter .k-grid-header .k-header.k-grid-header-sticky{position: relative !important;}
.view-right .ant-col.item{padding: 4px 12px;}
.refer-code{right: 30px;top: 44px;}
.record-history{width: auto !important;}
.footer-links{display: flex; justify-content: space-around;}
.phone-vth-code #advanced_search_phoneNumber{margin-top: 46px;}
.ant-alert-warning{align-items: center !important;}
.depositcrypto-newbtn{display: flex;}
.depositcrypto-newbtn .primary-btn{margin-top: 0 !important;}
.record-history{margin: 10px 0px 10px 0 !important;}
.ribbon-item{border-right: 0 !important;}
.k-grid-header .k-header.k-grid-header-sticky, .k-grid-header .k-filter-row .k-grid-header-sticky, .k-grid-content-sticky, .k-grid-row-sticky, .k-grid-footer-sticky{position: inherit !important;}
.bank-view .kpi-List{padding: 12px 16px;}
.kpi-List .ant-col{margin-bottom: 16px ;}

.responsive_table{width:100%; overflow-x: auto;}
.responsive_table tr th, .responsive_table tr td{min-width:150px !important}
.mb-view.cancel-btn{ margin-top: 40px !important;padding: 0 6px !important;}
.toolaction-mb{display: block;}
.mb-action{display: none !important;}
.admin-actions-mb{list-style: none;padding: 8px 12px;}
.admin-actions-mb li{margin-bottom: 10px;}
.action-title-mb{text-transform: capitalize;}
.mb-d-none{display: none;}
.customer-table-header .k-grid-header-sticky div div{left:222px !important;}
.profileinfo{padding: 15px 0;}
.side-drawer .ant-drawer-header{padding: 20px!important;}
.commission-form{overflow: auto;}
.inside-minmax tr{padding: 10px;}
.commision-table input[type="text"]{width: 100px !important;display: block;}

}
@media (min-width: 992px) {
    .depositcrypto-newbtn .primary-btn{margin-top: 0 !important;}
    .report_container{margin: -32px  !important;}
    /* .report-styles{height: 837px} */
    .report-styles iframe {
        border: none !important;
    }
    .phone-vth-code #advanced_search_phoneNumber{margin-top: 32px;}
    .view-left{border-right: 1px solid var(--borderLight); background: #f5f5f5;}
    .toolaction-mb{display: none;}
    .mb-d-none{display: none;}
    .profileinfo{padding: 32px 0;}
    .basicinfo{font-size: 24px!important;}
    .profile-tabs{width: 966px;}
    .mobile-ml-8{margin-left: 12px;}
    .ant-popover-inner-content{padding-top: 22px;}
    .addcommission{overflow: auto;}
    .addcommission .commision-table{width: 1474px !important;}
    .secureDropdown.appDropdown{left: 204px !important;width: calc(100% - 230px);}
    .secureDropdown.ant-dropdown{top: 65px !important;}
    .secureDropdown.depwith-drpdown.transacton-drpdwn.ant-dropdown{top: 265px !important;}
    .secureDropdown .ant-dropdown-menu{min-width:100%;max-width: 100%;}
    .appmenu-list li{width: 16%;}

}
@media(min-width:1200px){
    .view-right .item{padding: 14px;}
    /* .report-styles{height: 1015px} */
    .graph{min-height: 472px;}
    .page-title{font-size: 12px !important;width: 100%;}
    .main-container{max-width: 1232px; padding: 30px 16px 24px;}
    .profile-tabs{width: 966px;}
    .custom-control-codes  .k-grid-content.k-virtual-content .k-grid-table{width: 100% !important;}
    .custom-control-codes .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
    .customerwallets-grid .k-grid-header .k-grid-header-wrap table{width:1068px;}
    .customerwallets-grid .k-grid-content.k-virtual-content .k-grid-table{width:1068px;}
    .maintable.balances-table .k-grid-content.k-virtual-content table{width: 100% !important;}
    .maintable.balances-table .k-grid-header .k-grid-header-wrap table{width: 100% !important;}

}
/* ZOHO*/
.zsiq_flt_rel{width: 50px !important; height: 50px !important;bottom: 8px;right: -10px;}
.zsiq_theme1 .siqico-chat:before{font-size: 20px !important; line-height: 50px !important;}
@media(min-width:1367px){
    .createcase-input .ant-select-selector{height: 48px !important;}
}
@media(min-width:1400px){
    .main-container{max-width: 1320px; }
    .cust-success{right: 40px !important;top: 56px !important;}
    .custom-success{right: 84px !important;top: 40px !important;}
    .refer-green.custom-referral.text-left{right: 40px;}
    .view-right .item{padding: 20px}
    .cust-input, .expiry-input,.cust-input .ant-input, .cust-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 44px;}
    .ant-modal-footer .ant-btn-primary, .primary-btn{height: 48px !important;}
    .cust-input, .expiry-input,.cust-input .ant-input, .multi-select .ant-select-selector{height: 48px !important; }
    .cust-input.ant-select-single .ant-select-selector .ant-select-selection-item, .cust-input, .cust-input .ant-select-selection-placeholder{line-height: 48px !important;}
    .cust-input.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 48px;line-height: 48px;}
    .gridaction-btn{height: 22px !important;}
    /* .report-styles{height: 1408px} */
    .report_container{margin: -32px  !important;}
    .report-styles iframe {
        border: none !important;
    }
    .db-kpi .db-kpi-val{display: block;}
    .val-swap{padding: 10px 16px;}
    .pay-list{padding: 16px 0;}
    .Company-custom-table .ant-table-cell{width:250px;}
    /* .custom-transfer-grid .k-grid-header .k-grid-header-wrap table{width:1523px !important;}
    .custom-transfer-grid .k-grid-content.k-virtual-content .k-grid-table{width:1523px !important;} */
    .custom-control-codes .k-grid-header .k-grid-header-wrap table,.reconcilation-grid .k-grid-header .k-grid-header-wrap table,.balances-table .k-grid-header .k-grid-header-wrap table{width:1237px !important;}
    .custom-control-codes  .k-grid-content.k-virtual-content .k-grid-table,.reconcilation-grid .k-grid-content.k-virtual-content table,.balances-table .k-grid-content.k-virtual-content .k-grid-table{width:1237px !important;}
    .customerwallets-grid .k-grid-header .k-grid-header-wrap table{width:1200px;}
    .customerwallets-grid .k-grid-content.k-virtual-content .k-grid-table{width:1200px;}
    .profile-info .img-upld{right:3px !important;bottom:27px !important;}
    .pop-btnflex .cancelpop-btn{height: 48px !important;}
    .reclgrid-ncls .k-grid-content.k-virtual-content table{width: 100% !important;}
    .reclgrid-ncls .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
    .custom-control-codes  .k-grid-content.k-virtual-content .k-grid-table{width: 100% !important;}
    .custom-control-codes .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
    .auditlogs-grid  .k-grid-content.k-virtual-content .k-grid-table{width: 1522px;}
    .auditlogs-grid .k-grid-header .k-grid-header-wrap table{width: 1522px;}
    .maintable.balances-table .k-grid-content.k-virtual-content table{width: 100% !important;}
    .maintable.balances-table .k-grid-header .k-grid-header-wrap table{width: 100% !important;}
    .excel-btn .detail-popbtn{padding: 14px 36px !important;}
    .commision-table input[type="text"]{width: 100% !important;}
    .commition-input-bg,#partnerPercentage,#subPartnerPercentage{width: 100%;}
    /* .secureDropdown.appDropdown .ant-dropdown-menu{min-width:1390px;max-width:1390px;} */
    .secureDropdown.appDropdown{left: 216px !important;}
    .referralparty-icon { position: absolute; top: 35px; right: 16px;}
}
@media (min-width: 1500px){
    .main-container{margin: 0px auto;max-width:min(1460px, 100% - 40px)}
    .main-container.cust-footer {margin: 0px;max-width:min(1548px, 100% - 16px)}
    .business-check{top: 40px !important; right: 58px !important;}

}
@media (min-width: 1800px){
    .addcommission .commision-table,.table-scroll .commision-table{width: 100% !important;}
}
@media (max-width: 991px){
    .user-profile{width:42px;height: 42px;}
    .basicinfo{font-size: 20px !important;}
    .user-list{padding-left: 0;display: block !important;}
    .mobile-list .ant-list-item{display: block; border-bottom: 1px solid  var(--borderLight) !important;}
    .mobile-list .custom-btn, .crypto_btns .custom-btn{min-width: calc(50% - 22px);}
    .mobile-list .custom-btn:first-child{margin-left: 0 !important;}
    .mobile-list .crypto-btns{display: flex;padding-top: 16px;}
    .coin-typo{font-size: 12px !important;}
    .ant-modal-wrap.megamenu-backdrop{left:12px !important}
    .sidebar-push {margin: 100px 0 0;}
    .mobile-header-right {display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
    .mobile-headerview{display: none;}
    .mobile-headerview{margin-top: 15px;}
    .mobile-headerview li{margin: 0 34px}
    .mobile-headerview li:nth-of-type(6), .mobile-headerview li:nth-of-type(7){display: none;}
    .login-user{width: 100%;padding: 8px 0;}
    .crypto_btns{display: flex; width: 100%; margin-top: 16px;}
    .hidden-mobile{display: none;}
    .profileinfo{padding: 15px 0;}
    .profile-value, .custom-toggle, .mobile-ml-8,  .themeSwitchOff{margin-left: 0 !important;}
    .visible-mobile .ant-tabs-top > .ant-tabs-nav::before{border-bottom: 0 !important;}
    .visible-mobile .user-list .ant-tabs-nav{width: auto;}
    .mobile-mb-16{margin-bottom: 16px;}
    .bill_payment{display: block;}
    .sideropen{margin-left: -16px;padding: 12px;margin-top: 5px;border-radius: 0;min-height: 100vh;
        /* border-top-right-radius: 6px;border-bottom-right-radius: 6px; */

    }
    .coin-bal{display: block !important;}
    .coin-bal ul{ margin-top: 16px !important; text-align: center;}
    .wallet-mb{display: inline-block !important;}
    .mb-list .ant-list-item{display: flex !important;}
    .tlv-header .ant-layout-sider{min-height: 100vh;}
    .sideropen .ant-menu-vertical,.ant-menu-vertical{border-right: 0 !important;}
    .sideropen .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: transparent;}
    .sideropen .list-item.ant-menu-item-selected .ant-menu-title-content{min-width: 150px !important; padding: 8px 5px 8px 5px !important}
   .portfolio-data{display: block}
   .portfolio-list h1{padding: 0 0 6px;}
   .portfolio-list ul li{display: block !important;}
   .portfolio-title{align-items: center;}
   .ribbon-item{border-right: 0 !important;}
   .responsive_table{overflow-x: auto;}
   .responsive_table .pay-grid{width: 100%;}
   .bg-none{background-color: transparent !important;padding: 0; border-radius: 0;}
   .mb-view thead tr th{background: var(--bgDark) !important;}

   .header-logo .visible-mobile::after{content: '';position: absolute;width: 1px;height: 27px;right: 0;top: -2px !important;background-color: var(--bgWhiteSpin);}
   .mobile-logo {padding-left: 20px;}
   .custom-label .ant-form-item-label{margin-bottom: 8px !important;}
   .amt-field .cust-input{width:150px;}
   .sidebar-push .ant-alert{align-items: baseline;}
   .custom-label.ant-form-item{margin-bottom: 16px;}
   .mb-flex-none{display: block !important;}
   .mb-view tfoot{background: #515a64 !important;}
  .mb-view tr th, .mb-view tr td{min-width:150px !important}
   /* .layout.ant-layout-has-sider .icon.lg.hamburg{width: 24px; height: 24px;background: var(--lightThemeIcons) no-repeat; display: inline-block; border-radius: 6px;background-position: -55px 2px; transform: scale(1.4);} */
   /* .layout .icon.md.close-white{width: 24px; height: 24px;background: var(--lightThemeIcons) no-repeat; display: inline-block; border-radius: 6px;background-position: -55px 2px; transform: scale(1.4);} */
   .cust-cypto-view{width: auto !important;}
   .custom-alert-width{width: auto !important;}
}
.search-bg{border: 1px solid;border-color: rgba(0, 0, 0, 0.08);color: #424242;background-color: #fafafa;padding: 15px 10px;}
.ant-select-open.ant-select-show-search .anticon-search svg{opacity: 0;}
.ant-select.ant-select-open.ant-select-focused.ant-select-enabled
  .ant-select-selection.ant-select-selection--single
  .ant-select-selection__rendered
  .ant-select-selection-selected-value {
  visibility: hidden;
}

.ant-select.ant-select-open.ant-select-focused.ant-select-enabled
  .ant-select-selection.ant-select-selection--single
  .ant-select-selection__rendered
  .ant-select-selection-selected-value::before {
  visibility: visible;
  content: "Select a Person";
}
.search-bg .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{cursor: pointer !important;}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{cursor: pointer;}
.ant-select-disabled.ant-select-show-search .ant-select-selector{cursor:not-allowed !important;}
.cust-adon .ant-input-group, .cust-adon .ant-input-group .ant-input:focus, .cust-adon .ant-input, .cust-adon .ant-input-group-addon{border: none !important;box-shadow: none;padding: 0;}
.cust-adon .ant-input{height: 36px !important;background-color: transparent !important; padding:0 1px !important}
.roles-custom{width: 100%;text-align: left;table-layout: auto;border-radius: 2px 2px 0 0;border-collapse: separate;border-spacing: 0;border: 1px solid #dcdcdc;}
.roles-custom td{border-right: 1px solid #f0f0f0;transition: background 0.3s;border-bottom: 1px solid #f0f0f0;}

.ant-select-item-group{background: #f1f1f1;font-size: 14px;font-weight: 700;color: #0E0E2C;border-bottom: 1px solid #ddd;border-top: 1px solid #ddd; }
.ant-select-item-option-grouped{padding-left: 18px;}
.custom-overflow #advanced_search_phoneNumber, .custom-overflow .cust-input{overflow: unset !important;}
.row-dragging {background: #fafafa;border: 1px solid #ccc;}
.row-dragging td {padding: 4px 12px;}
.row-dragging .drag-visible {visibility: visible;}
.drag-visible .custom-checkbox{display: inline-block;}
.icon.md.reset-password {background-position: -432px -60px;}
.icon.md.app-menu {background-position: -458px -62px;}
.custom-import .ant-upload-list-item-name {text-align: left;}
.ant-alert-warning {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
    width:fit-content;
}
.ant-alert-message {
    font-size: 18px!important;
    font-weight: 700;
    color: var(--textDark)!important;
    line-height: normal;
}
.send-panel{display: flex;background-color: #EBF0F3;padding: 0;}
.left-panel{width: 450px; background-color: #fff;border-radius: 10px; padding: 10px;}
.left-panel .ant-input-search-button{display: none;}
.right-panel{flex-grow: 1; margin-left: 16px;background-color: #fff;border-radius: 10px;}
.coin-search{border-radius: 30px; background: #ecf1f4;padding: 6px;margin-bottom: 16px;margin-top: 8px;}
.demo-loadmore-list{ overflow-y: auto;max-height: calc(100vh - 265px);}
.demo-loadmore-list .ant-list-item:hover{background-color:#f0f8ff ;}
.coin-panel{display: flex; align-items: center;justify-content: center; border-bottom: 1px solid #f2f2f2;padding-bottom: 10px;}
.icon.md.search{background-position: -197px 0;}
.icon.md.doc{background-position: -457px -165px;}
.address-input .ant-input{text-align: center;}
.share-adrs{display: flex}
.company-List .ant-col{margin-bottom: 34px;}
.company-List{background-color: var(--bgDarkGrey);border-radius: 30px;padding: 34px 34px 0;}
.custom-kyc-doc a{padding: 2px 0px; margin: 0px}
.file-labels{border: 1px solid var(--borderLight);border-radius: 6px;padding: 2px 8px;color: var(--textLink);background-color: var(--bgDarkGrey);width: 110px;text-align: center;display: inline-block;}

.record-history{background-color: var(--bgDarkGrey);
    border-left: 2px solid green;margin:20px;width:100%;padding: 10px;margin-bottom: 16px;margin-top: 50px;}
/* .docdetails .docnames{font-weight: 700; color: var(--textLink) !important; line-height: 16px;max-width: 150px !important;
border: 1px solid var(--borderLight);border-radius: 6px;
padding: 2px 8px;color: var(--textLink);cursor: pointer;background-color: var(--bgDarkGrey);} */
.files-labels{width: 115px;padding:0px 5px;text-align:center;border: 1px solid var(--borderLight);border-radius: 6px;color: var(--textLink);background-color: var(--bgDarkGrey);}
.pay-docs{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 85px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.pay-doc{white-space: nowrap;max-width: 250px !important;overflow: hidden; text-overflow: ellipsis; width: 180px;word-break: inherit; display: inline-block; vertical-align: bottom;}
.value-field{margin-top: 32px; border-radius: 0px; margin-right: 20px;border-top-right-radius: 25px;border-bottom-right-radius: 25px;}
.head-navsec{padding: 0 20px !important;height: 45px !important;
   position: sticky;
    right: 0;
    top: 63px;}
.custom-width{width:600px !important;}
.settings-bar{z-index: 1;}
.docdetails .docnames{font-weight: 500;color: var(--textWhite30); line-height: 16px;max-width: 150px !important;}
.text-blue{color:rgb(0, 132, 255) !important;}
/* .docdetails .docnames{color:#0b81d0 !important;} */
/* modalissuechnages */
.ant-tooltip-inner a{color: var(--textWhite); font-size: 16px;}
.ant-tooltip{z-index: 9999 !important;}
.docdetails .docnames{font-weight: 700;color: var(--textLink); line-height: 16px;max-width: 150px !important;}
textarea.ant-input.cust-input{line-height: 1.578 !important;}
.icon.md.Kompany{background-position: -688px -57px;}
.icon.md.risk-screen{background-position: -714px -57px;}
.icon.md.lock-unlock{background-position:-740px -57px;}
.icon.md.Tier{background-position:-1016px -58px;}
/* .icon.md.unlock{background-position:-766px -57px;} */
.icon.md.change-phone{background-position:-1043px -57px;}
.icon.md.change-mail{background-position:-334px -57px;}
.icon.md.suisse-edit{background-position:-989px -58px;}
.icon.lg.PersonalBanks{background-position: -583px -155px;}
.ant-menu-item-selected .icon.lg.PersonalBanks{background-position:-583px -188px;}
.icon.lg.ProviderFees{background-position: -617px -155px;}
.ant-menu-item-selected .icon.lg.ProviderFees{background-position:-617px -188px;}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){border-color: var(--bgYellow) !important;}
.refer-green{color: green;right: 18px;text-align: right;width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    position: absolute;
    font-weight: bold;}
.main-content .anticon-download{color:#000;}
.main-content .anticon-download:hover{color:#0b81d0;}
.custom-status-grid .k-grid-header{background-color: #ecf1f4;}
.custom-status-grid   .k-i-sort-asc-sm::before{display: none;}
.checklist-custom-width{width: 170px;float:left;}
.powerbi-placeholder{width: 100%;height: 40px;background-color: #fff;position: absolute;left: 0;right: 0;top: 1px;}
.view-right .ant-col .risk-changes{margin-top: 7px;}
.pay-list{display: flex;justify-content: space-between;align-items: center;}
.pay-list{padding: 16px 0; border-bottom: 1px solid var(--borderLight);}
.icon.md.lftarw-white{background-position: -377px 0;}
.custom-datepiker .ant-picker{ background-color: transparent;border: none;}
.custom-datepiker .ant-picker-focused{box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);}
ul.transaction-list li{list-style: none;float: left;}
.ant-checkbox .ant-checkbox-inner, .check-lg.custom-checkbox input[type=checkbox]+span{width: 30px;height: 30px;cursor: pointer;background-position: -84px -260px;}
.check-lg{width: 30px;}
.check-lg.custom-checkbox input[type=checkbox]:checked+span{width: 30px;height: 30px;cursor: pointer;background-position: -122px -258px;}
.addCryptoList .cry-add {
    padding: 10px;
    color: var(--textDark);
    border-bottom: 1px solid var(--borderGrey);
    font-size: 12px;}
/*.addCryptoList .ant-select-item-option-content .cry-add:hover {
        background-color: var(--bgBlu);
        cursor: pointer;}*/
/* .checklist-custom-width{width: 170px;float:left;}
.action-title-mb:hover{background-color:rgba(0,0,0,.1)}
.action-title-mb{list-style: none !important;align-items: center;font-size: 14px;padding: 8px 15px;}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow{right: 15% !important;left: auto !important;} */
.cust-disable{cursor:not-allowed;color: var(--textDark) ;background-color: #f7f7f7 ;}
/*.ant-select-item.ant-select-item-option.addCryptoList.ant-select-item-option-active.ant-select-item-option-selected{background-color:#f5f5f5;}
.addCryptoList.ant-select-item-option-active:not(.ant-select-item-option-disabled){background-color:#f5f5f5;} */
.action-title-mb{list-style: none !important;align-items: center;font-size: 14px;padding: 8px 15px;}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow{right: 15% !important;left: auto !important;}
.input-label .ant-row {display: block !important;margin-bottom: 20px;}
.record-history.cust-record-history{width:100% !important;}
.cust-input.value-field.cust-addon{border: transparent;padding-left: 0 !important;padding-right: 0 !important;}
.cust-input.value-field.cust-addon .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{border: 1px solid #d9d9d9;border-top-right-radius: 0;border-bottom-right-radius: 0;}
.cust-input.value-field.cust-addon .ant-input-group-addon{background-color: transparent;border: transparent;}
.cust-input.value-field.cust-addon .ant-input-group > .ant-input:last-child{border-left: 0;}
.k-grid-content{overflow-y:auto;}
.cust-doc .ant-modal-body{padding-top: 0px;}
.value-field.cust-addon .ant-input:focus{box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); border-color: var(--bgDark) !important;}
/* .value-field.cust-addon .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{cursor:;} */
.table-grid-change .custom-checkbox{position: relative;right: 10px;}
.table-grid-change .k-master-row .k-grid-content-sticky{border-color: rgba(0, 0, 0, 0.08) !important;}
.table-grid-change .k-grid-header .k-grid-header-sticky{border-color: rgba(0, 0, 0, 0.08) !important;}

.custom-toggle.ant-switch{background-color: var(--bgBlack) !important;}
.custom-toggle.ant-switch-checked{background-color: var(--textError) !important;}
.ant-select-multiple .ant-select-selection-item-remove, .ant-select-multiple .ant-select-selection-item-content{height:30px;}
/* .cust-input-light{background: transparent;
    border-radius: 30px;
    height: 50px;
    border: 1px solid #6e6e74;
    color:#6e6e74 ;
    text-align: center;
    font-size: 16px;
} */
.notifications-list .ant-list-item-meta-avatar{border-radius: 50%;width: 38px;height: 38px;text-align: center;line-height: 38px;}
.notifications-list .ant-list-item-meta-title{margin-bottom: 0 !important;}
.notifications-list .ant-list-item-meta{align-items: center;}
.notifications-list .ant-list-item{padding:16px 0; border-bottom: 1px solid var(--borderGrey) !important;}
.icon.lg.notifyIcon.deposit{background-position: -375px 0px;transform: rotateZ(235deg);}
.icon.lg.notifyIcon.deposits, .icon.lg.notifyIcon.withdraw{background-position: -376px -1px;transform: rotateZ( 45deg);}
.icon.md.notifyIcon.documents, .icon.md.notifyIcon.cutomers{background-position: -156px -71px;}
.icon.md.notifyIcon.cases{background-position: -213px -332px;}
.icon.md.notifyIcon.payments{background-position: -481px -3px;}
.icon.lg.notifyIcon.buy, .icon.lg.notifyIcon.sell{background-position: -192px -95px;}
.pay-grid tr:nth-child(even){
    background-color: var(--bgDarkGrey);
}
.pay-grid td{padding-bottom: inherit !important;}
.cust-input-light{color:#bfbfbf;border-color: #d9d9d9;line-height: 30px;margin-bottom: 5px;border-radius: 50px;}
.cust-input-light .ant-select-selection-item{color:#bfbfbf !important;border-color: #d9d9d9;height: 30px;line-height: 30px;margin-bottom: 5px;border-radius: 50px;border: 1px solid #f0f0f0;font-size: 14px;}
.cust-input .ant-select-selection-item{
    font-size: 14px !important;}
    .cust-input-light .ant-select-selection-placeholder{font-size: 16px !important;}
.buy .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.deposit .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.paymentsbg .ant-list-item-meta-avatar{background-color: var(--bgDarkGreen);}
.sell .ant-list-item-meta-avatar{background-color: var(--textError);}
.withdraw .ant-list-item-meta-avatar{background-color: var(--textError);}
.casesbg .ant-list-item-meta-avatar{background-color: var(--bgBlue);}
.menu-ul{position: absolute;top: 0;right: 0;width: 100%;}
.sub-menu{top: 0;position: absolute;left: auto;opacity: 1;width: 100%;z-index: 9;}
.k-grid.table-checkalign table td:first-child{padding: 8px 7px !important;}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon{font-size: 23px;line-height: 51px;}
.notification-conunt .bell span{position: absolute;right: -5px;top: 11px;width: 18px;height: 18px;background: #f6921e;border-radius: 50%;line-height: 18px;text-align: center;font-size: 10px;font-weight: 600;}
.notifications-list .ant-list-item-meta-avatar{border-radius: 50%;width: 38px;height: 38px;text-align: center;line-height: 38px;}
.notifications-list .ant-list-item-meta-title{margin-bottom: 0 !important;}
.notifications-list .ant-list-item-meta{align-items: center;}
.notifications-list .ant-list-item{padding:16px 0; border-bottom: 1px solid var(--borderGrey) !important;}
.ant-notification-notice .ant-notification-close-icon{color: var(--textWhite30);}
.ant-notification{direction: ltr;}
.ant-notification-notice-closable .ant-notification-notice-message{padding-left: 0 !important;}
.ant-notification-rtl .ant-notification-notice-close{left: auto;right: 15px;}
.ant-notification-notice-message{color: var(--textWhite30)!important;}
.ant-notification-notice-description{color: var(--textSecondary)!important;}
.ant-notification-notice{background-color: var(--bgDarkGrey);border-radius: 10px;font-size: 14px;}
.custom-status-grid table{width:950px !important;}
.kpi-labels{font-size: 12px;color:solid black}
.asignedto-input-height .ant-select-selector{height: 38px;}
.create-case-error .ant-form-item-explain-error{margin-top: 12px;}
/* .kpi-val.c-pointer a:hover,.kpi-val.c-pointer a:active,.kpi-val.c-pointer a{color:var(--textDark)} */
.custom-status-grid.send-custom-grid th .k-link{cursor: default;}
.whitelist-name .white-address{ width:100% ; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;}
.create-case-error .ant-form-item-explain-error{margin-top:6px;}
/* .doccancel-pop{color: var(--textWhite30);background-color: transparent !important;font-size: 14px;padding: 6px 30px !important;border-radius: 20px;border: 1px solid grey;} */
.writemsg-input .ant-row.ant-form-item{margin-bottom: 0px;}
.writemsg-input{position: relative;}
.writemsg-input .ant-form-item-explain-error{position: absolute;bottom: -40px;}
.upload-filedoc{margin-top: 30px;}
.createcase-input .ant-select-selector{height: 38px;}
.writemsg-input .ant-form-item-has-success{flex: 200px;}
.writemsg-input .ant-form-item{flex: 1 !important;}
/* .popcancel-btn{border-radius: 10px;} */

/* .uploaddoc-popup .ant-popover-buttons .ant-btn-sm:first-child{padding: 6px 30px;border-radius: 10px;}
.uploaddoc-popup .ant-popover-buttons:first-child{padding: 6px 30px;border-radius: 10px;}
.doc-cancel .ant-popover-buttons{margin-bottom: 20px;} */

/* .kpi-val.c-pointer a:hover,.kpi-val.c-pointer a:active,.kpi-val.c-pointer a{color:var(--textDark)} */
/* .kpi-val.c-pointer a:hover,.kpi-val.c-pointer a:active,.kpi-val.c-pointer a{color:var(--textDark)} */
.profile-info{width: 180px;height: 180px;padding: 5px;border-radius: 50%; margin: 0 auto 24px;background-image: linear-gradient(128deg, var(--bgDark1) 50%, var(--bgDark1) 50%); position: relative;}
.profile-info .user-profile{width:175px;height:175px; border-radius: 50%;padding: 6px;background-color: var(--bgDarkGrey);object-fit: cover;}
.profile-info .img-upld{position: absolute;right: 3px;bottom: 27px;background-color: var(--bgYellow);border-color: var(--bgYellow);padding: 0;}
.icon.xl.camera{background-position:-868px -51px;}
.icon.md.download{background-position: -903px -59px;}
.basicinfo{ font-weight: 600 !important; color: var(--textWhite30) !important; line-height: 28px !important;}
.basic-info, .contact-info{padding: 24px;}
.user-list{list-style: none;}
.profile-label{font-size: 14px; color: var(--textWhite30); font-weight: 400; }
.profile-block{display: block;}
.coin-bal ul li{display: inline-block;list-style: none;font-size: 10px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-left:16px;}
.custom-theme-btn,.profile-block{display: flex !important;}
.profile-value{font-size: 16px; color: var(--textWhite30); font-weight: 700;margin-left: 8px;}
.main-container .k-loading-color{background-color: transparent !important;}
.main-container{padding: 24px 16px; margin: auto;}
.hidden-mobile{display: none;}
.user-tablist .ant-tabs-tab, .user-tablist .ant-tabs-tab.ant-tabs-tab-active, .user-tablist .ant-tabs-tab:hover{padding: 12px 16px 12px 12px !important;}
.user-list.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations{display: none;}
.user-list .ant-tabs-tab{font-size: 16px; font-weight: 600; color: var(--textWhite);margin: 0 2px;}
.user-list.ant-tabs-left > .ant-tabs-content-holder{border-left: 0 !important;}
.user-list .ant-tabs-content.ant-tabs-content-left{padding-left: 65px;}
.user-list .ant-tabs-tab.ant-tabs-tab-active,.user-list .ant-tabs-tab:hover {background: var(--bgDarkYellow);font-weight: 600; border-radius: 16px !important; padding:12px; color: var(--textDark);}
.user-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--textDark);}
.user-list .ant-tabs-ink-bar {background: transparent !important;}
.basic-decs{font-size: 16px; font-weight: 400; color: var(--textWhite30);}
.coin-bal{display: flex;justify-content: space-between;align-items: center;}
.coin-bal ul li{display: inline-block;list-style: none;font-size: 10px;text-align: center;color: var(--textWhite30);font-weight: 500;margin-left:16px;}
.coin-bal ul li div{width: 40px;height: 40px;border-radius: 50%;background-color: var(--bgGloom);margin: 0 auto 6px;line-height: 38px;box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);}
.box.dash-info{border-radius: 0 !important;}
.box{background-color: #ecf1f4;border-radius: 16px; overflow: hidden; margin-bottom: 28px;}
.box.basic-info.alert-info-custom{background-color:var(--bgDark) !important;border-radius: 15px;color: var(--textWhite);border-color: var(--borderGrey) !important;}
.profileinfo{border-top:1px solid var(--borderGrey);}
.profileinfo:first-child{border-top:0}
.profileinfo.active, .profileinfo:hover{background: var(--bgOrient1); margin-left: -32px; margin-right: -32px;}
.profileinfo.active .profile-block, .profileinfo:hover .profile-block{padding: 0 32px}
.profile-tabs .ant-tabs-nav{text-align: center;margin: 0 auto;}
.profile-tabs .ant-tabs-nav-wrap{line-height: 0.9;padding: 10px;background: #ecf1f4 !important;border: 0;border-radius: 16px;}
.profile-tabs .ant-tabs-nav-list{padding: 0;}
.icon.lg.profile-icon{background-position: -5px -325px;}
/* .ant-tabs-tab.ant-tabs-tab-active .icon.lg.profile-icon, .ant-tabs-tab:hover .icon.lg.profile-icon, .user-labels.active .icon.lg.profile-icon, .user-labels:hover .icon.lg.profile-icon{background-position: -5px -325px;} */
.icon.lg.security-icon{background-position: -109px -325px;}
.icon.md.arrow-new{background-position: -217px -3px;}
.icon.md.x-cpwd{background-position: -511px -2px;}
.profile-tabs .ant-tabs-nav-wrap{margin-bottom: 20px;}
.profile-tabs .box{margin-bottom: 15px;}
.ant-spin-dot-item{background: #0E0E2C;}
.change-space .ant-input-password-icon svg{color: #0E0E2C;}
.ant-input-suffix, .anticon-eye-invisible.ant-input-password-icon{color: #0E0E2C;}
.side-drawer .side-drawer-header{align-items: flex-start;}
.pwd-popup.pop-cancel{vertical-align: middle;margin: 10px auto !important;height: 36px !important;text-align: center !important;box-shadow: none;}
.pop-cancel {background-color: transparent !important;color: var(--textWhite30) !important;border-color: transparent !important;text-transform: capitalize !important;height: 57px;border: 0 !important;font-weight: 400 !important;text-align: center !important;padding: 0;}
.change-space .ant-input{padding-left: 0 !important;padding-right: 0 !important;height: auto;}
.profile-btn{background: var(--bgDarkGrey);border: 1px solid #ed4b9e;height: 38px;border-radius: 19px;color: var(--textWhite);font-size: 12px;font-weight: 400;margin: 0 auto;padding: 10px 14px; width: 200px;text-align: center;margin-top: 24px;}
.profile-btn a{margin: 0 auto;display: flex;color: #0E0E2C !important;}
.profile-acname{margin-right: 8px;}
.default-user{width: 26px;height: 26px;border-radius: 50%;}
.side-drawer .custom-forminput input::placeholder{text-align: center !important;}
.side-drawer .custom-forminput input{text-align: center;}
.menuactive{background-color: var(--bgDarkGrey);}
.basic-details ul li:hover{background-color: #dee3e6;}
.basic-details ul li{border-bottom: 1px solid #dee3e6;}
.input-txtcenter input{text-align: center;}
.pop-btnflex{text-align: right;}
.pop-btnflex .cancelpop-btn{border: 1px solid var(--bgYellow) !important;}
.pop-btnflex .cancelpop-btn{margin: 0px !important;margin-left: 10px !important;}
.pop-btnflex .ant-btn{width: 142px;}
.pop-btnflex .cancelpop-btn{margin: 0px !important;margin-left: 10px !important;height: 38px;}
.erroralert-icon .anticon-close-circle{margin:3px 8px 0px 0px !important ;}
/* .ant-select-dropdown{z-index: 2 !important;} */
.icon.md.Customer{background-position: -1px -60px}
.icon.md.Country.of.Residence{background-position: -306px -59px}
.icon.md.From.Wallet{background-position: -201px -60px}
.icon.md.To.Wallet{background-position: -201px -60px}
.icon.md.To.Value{background-position: -659px -60px}
.icon.md.From.Value{background-position: -659px -60px}
.icon.md.Provider{background-position: -659px -60px}
.icon.md.State{background-position: -438px -31px}
.icon.md.Coin{background-position: -201px -60px}
.icon.md.Deposited.Coins{background-position: -267px -30px;}
.icon.md.Wallet.Code{background-position: -201px -60px}
.icon.md.Requested.Crypto{background-position: -267px -30px;}
.icon.md.Wallet.Address{background-position: -201px -60px}
.icon.md.Token{background-position: -85px -60px}
.icon.md.Network{background-position: -201px -60px}
.icon.md.Bank.Account{background-position: -296px -30px}
.icon.md.Country.of.Business{background-position: -306px -59px}
.header-right .ant-dropdown-trigger::after{border-bottom: 0 !important;}
.roles-inactivemodel .ant-modal-body{padding: 0px 24px 24px;}
.banks-errmsg .ant-alert-message{margin-bottom: 2px;}
.case-ribbon .ribbon-item .icon.md.Currency{background-position: -201px -60px}
.editmode-greentext{ margin: 6px 0px 0px 6px !important;}
.send-poperror{margin-bottom: 15px;}
/* .text-right{text-align:right;padding-bottom:16px;} */
.icon.lg.BatchPayments{background-position: -1119.5px -90px}
.icon.lg.BatchPayments:hover,.icon.lg.BatchPayments:active{background-position: -1120.5px -127px;}
.ant-menu-item-selected .icon.lg.BatchPayments{
    background-position: -1120.5px -127px;}
.batch-excel{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.icon.md.download:hover{background-position: -932px -59px !important;}
.table-data-align{margin-top: 8px;}
.icon.md.Batch.ID{background-position: -501px -200px;width: 26px;height: 26px;}
table td:nth-child(2) .gridLink,table td:nth-child(1) .gridLink{margin-top: 7px;}
.file-labels.address-label{width: 100%;max-width: 150px;}
.riskDocs{padding: 0px 16px 0px 0px !important;display: block;white-space: nowrap;width: 16em;overflow: hidden;text-overflow: ellipsis;}
.riskdocfile{background-color: #ECF1F4;border-radius: 20px;padding: 16px;min-width: 300px;display: flex;align-items: center;margin: 0 0px 16px 0;}
.pr-8{padding-right:8px;}
.note-label .ant-form-item-label{margin-left: 0px !important;}
.upload-size{width: 320px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;}
.icon.xl.video{background-position: -482px -156px;}
.ant-popover-buttons button:first-child{border-color: var(--bgYellow); border-radius: 42px;padding: 0 36px; height: 38px;text-transform: uppercase;font-weight: 700;transition: 0.3s ease;color: var(--textDark);}
.customer-lastname label{height: fit-content;}
/* .ant-modal-mask,.ant-modal-wrap{z-index:9999;} */
.risk-status{font-size: 12px;}
.risk-uploads-col{margin-bottom:6px !important;padding-left: 0;}
.primary-btn.cust-download{line-height: 50px;}
.docfile.risk-uploads{background-color: #e7ebed;}
.cust-pop-doc{display: block  !important;}
.cust-upload-style{width:100%}
.c-notallowed{cursor:not-allowed !important;}
table{width: 100% !important;}
.sb-staff{margin-top: 6px;display: block;}
.icon.md.save-layout{background-position:-959px -58px}
.change-space.input-txtcenter input{background-color: transparent !important;}
.excel-btn .detail-popbtn{padding: 8px 36px;}
.business-check{top: 36px ; right: 58px ;}
.reject-btn:focus{border-color: var(--bgYellow) !important;}
.file-width.docfile{width:270px !important;margin-right: 10px!important;}
/* commission start  */
.commision-table,.Tier-table{border-color: rgba(0, 0, 0, 0.08);}
.commission-flex{align-items: end;justify-content: space-between;}
input[type="checkbox"] {vertical-align: middle;width: 18px; margin-right: 8px;}
.max-label+input{margin-top: 8px;}
.commision-table input[type="text"]{width: 100%;display: block;}
.Commission-Template{padding-top: 12px;}
.inside-minmax tr,.flat-row{padding: 8px 20px;display: flex;}
.Tier-table tr td,.Tier-table tr th{padding: 8px 12px;}
.Tier-table tr:hover:not(:nth-child(-1n+2)){background-color: #ededed;}
.commision-table td,.commision-table th{padding: 8px 12px;}
/* commission end */
.label-cust{margin-left: 13px;}
/* customer start  */
.table-header-row{background-color: var(--bgDarkGrey);}
.table-border{border-right: 1px solid var(--borderLight);}
.input-addon-style .ant-form-item-control-input-content{width: 75%;}
.input-addon-style .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{border-color: var(--bgDark);}
.input-addon-style{margin-bottom: 0;}
.customer-feesform{border: 1px solid #e2e2e2;border-bottom: none !important;}
.inside-minmax .ant-form-item-control-input-content{display: flex;align-items: center;}
.tablehead-minmax{display: flex;justify-content: space-around;}
.customised-input .cust-input{width: 120px;display: contents;}
.customised-input .ant-form-item-control-input-content{display: flex;align-items: center;gap: 8px;}
.customised-input{margin-bottom: 0;}
.customised-input .ant-input{padding-left: 0 !important;padding-right: 8px !important;}
.input-addon-style .ant-input-affix-wrapper{width: 100px;}
.edit-commition-table td,.edit-commition-table th{text-align: left;}
/* .inside-minmax tr td:first-child{padding-right: 0px !important;} */
.minmax-value{font-weight: 700;}
.view-commition-table tr:not(:first-child) td{padding: 4px 12px;}
.view-commition-table .greenCheck{cursor: not-allowed;}
.banks-edit{position: absolute;top: -23px;left: 146px;transform: scale(0.7);cursor: pointer;}
.margin-auto{margin: 0 auto;}
.commission-input input{width: 120px;padding: 0 12px !important;}
.table-scroll{width: 100%;overflow: auto;}
.table-scroll table{width: 1495px  !important;}
.commission-input-small input{width: 90px;padding: 0 12px !important;}
.section-label{margin-left: 14px;}
.monthly-fee{margin-left: 28px;}
/* cutomers end  */
/* receive start  */
/* receive end  */
/* commissions */
.src-checkbox{flex-direction:row-reverse;align-items:baseline;}
.cust-commission-br{border: 1px solid #00000014;}
.cust-popalign .ant-radio-wrapper-checked, .cust-popalign .ant-radio-wrapper{align-items: center;}
.commition-input-bg .ant-form-item-control-input-content{gap: 8px;}
.edit-commition-table input[type="text"]{padding-left: 16px !important;border-right: 0;}
.edit-commition-table input[type="text"]:focus,.edit-commition-table input[type="text"]:hover{border-color: var(--borderLight);box-shadow: none;}
.customised-input .ant-input-group-addon{border-top-right-radius: 30px;    border-bottom-right-radius: 30px;}
.onetime-monthly input[type="text"]{width: 100%;}
.onetime-monthly .ant-row:first-child{margin-bottom: 16px;}
.onetime-monthly{flex: 1;}
.responsive-table{overflow: auto;}
.ant-select-dropdown .ant-select-item-option-state {
    display: none;
  }
  .ant-select-selection-item .ant-checkbox {
    display: none;
  }
  .ant-select-dropdown .ant-select-item-option-state {
    display: none;
  }
  .row-border{border-bottom: 1px solid rgba(0, 0, 0, 0.08);}
  .inside-table tr td{ border-right: 1px solid rgba(0, 0, 0, 0.08);}
  .table-border-right{border-right: 1px solid rgba(0, 0, 0, 0.08);}
  .table-partner-head tr th:nth-child(3){ padding: 18px 12px;}
  .col-width{width:200px !important}
  .inside{ border-right: 1px solid rgba(0, 0, 0, 0.08);}
  .edit-section{align-items: end;}
  .btn-grp .ant-form-item-control-input-content{text-align: right;margin-top: 16px;}
  .commission-char input {
    width: 185px;
    padding: 0 12px !important;
}
.Template-Name .ant-row{flex-direction: column;}
.Template-Name .ant-form-item-label{text-align: left;}
/* .address-width{width: 150px !important; overflow: hidden;} */
/* .address-width{width: 143px !important;} */
.address-label-width{width: fit-content !important;}
.doctype-mr{margin-right: 36px;}
.available-banks .ant-select-selector{display: block;padding: 8px 18px;border-radius: 10px !important;}
.available-banks .ant-select-selection-item{border-radius: 30px;margin-bottom: 8px;}
.available-banks .ant-input-disabled, .ant-select-disabled, .ant-input-number-disabled{background-color: transparent !important;}
.available-banks .icon.md.edit.banks-edit{left: 117px;}
.view-bank{width: 100%;}
.cust-input-disabled{background-color:#f7f7f7 !important;}
.accordian .ant-collapse-extra span{display: inline-block; color: var(--textPureWhite);font-size: 12px;padding: 2px 8px; border-radius: 4px; font-weight: 500;}
.reject-lbl{background-color: red !important;}
.subm-lbl{background-color: blue !important;}
.Requet-lbl{background-color: #c7c70d !important;}
.check-bank{gap: 8px;align-items: baseline;}
.check-bank .custom-checkbox{display: block;}
.k-checkbox:checked {
    background: var(--bgYellow);
    border: var(--bgYellow);
    color: var(--textDark);
}
.k-state-selected {
    background-color: var(--bgYellow) !important;
    color: var(--textDark) !important
  }
  .checkbox-sweeepdestination{position: absolute;top: 5px;right: 24px;}
  .icon.md.resetlayout{background-position: -715px -2px;}
  .icon.md.editcomissions{background-position: -498px -273px;}
  .cust-log{max-height: 200px;overflow: auto;}
  .cust-log-footer{position: unset;}
  .default-template-modal .ant-modal-close-icon{cursor: pointer;}
  .available-banks .ant-form-item-label{margin-left:8px;}
  .view-right .ant-col.item:last-child{border-bottom: transparent;}
  .default-template-modal .ant-modal-body{background-color: #fff2f0;    border: 1px solid #ffccc7;}
  .default-template-modal .ant-modal-content{border-radius: 0;}
  .default-template-modal .ant-modal-close{top: 10px;}
  .log-row{display: block;}
  .bank-modal .ant-modal-body{padding-top: 8px;}
  .megamenu-title{font-size: 18px;font-weight: 600;display: inline-block;color: #000000;margin-bottom:8px;}
.text-align-inside{padding:16px 16px 8px;}
.appmenu-list{border: 1px solid var(--borderGrey);border-bottom: 1px solid var(--borderGrey) !important;}
.in-active{background-color: #f7f7f7 !important;color: #000;}
.commission-check{z-index: 1;}
.normal-template th:last-child{width: 136px !important;}
.normal-template th:nth-child(3){width: 108px !important;}
.available-banks{   border-top: 1px solid var(--borderLight);}
.custcase-toggle{width: 546px;}
.table-scroll.custTable table{width: 100%  !important;}

.k-icon.k-i-filter.left-five{
    left: 5px;
}
.ant-btn:hover, .ant-btn:focus, .ant-btn:active{color: #000000 !important;}
.docfile_show{width: 328px !important;}
.text-right.secureDropdown.export-pdf{margin-bottom: 28px;}
.drpdwn-list li{padding: 0 22px;}
.secureDropdown.export-pdf button{height: 38px;border-radius: 8px;}
.crypto-text-mb{margin-top: 36px;display: block;margin-bottom: 16px;}
.cust-cryptofiat-view tr th{background: var(--bgDarkGrey) !important;
    border-right: 1px solid #dcdcdc !important;padding:16px !important;text-align: left;}
.cust-cryptofiat-view tr td{padding: 8px 16px !important;border-right: 1px solid #f0f0f0;text-align: left;}
.cust-cryptofiat-view, .cust-cryptofiat-view tr{border: 1px solid #f0f0f0;}
.cust-cryptofiat-view tr:hover > td{background: #fafafa;}
.view-right .cust-row-design .ant-col.item{border: 0 !important;}
.view-right .cust-row-design{border: 1px solid var(--borderLight);margin: 10px;border-radius: 10px;}
.text-word-wrap{word-wrap: break-word;}
.sumsub-url{cursor: pointer;word-break: break-all;}
.referral-error{color: #ff4d4f;}
.k-animation-container{z-index: 10003 !important;}
.hide-filter .send-custom-grid .k-grid-header .k-icon.k-i-more-vertical{display: none !important;}
.reset-paswrd-mt{margin-top: 36px;}
.reset-paswrd-mb{margin-top: 27px;}
.icon.Sum-sub,.Sum-Sub{background-position: -744px -3px;}
.ant-modal-close-x .anticon-close{cursor: pointer;}
.cust-datepicker-cursor .ant-picker-suffix{cursor: pointer;pointer-events: auto !important;}
.mt-10{padding-bottom: 45px;}
.decalration-title{font-size: 24px;}
.text-nowrap{white-space: nowrap;}
.companybanks-table{overflow: auto;margin-bottom: 3px;}
.companybanks-table table{ border-collapse: collapse;width: 100%; min-width: 1250px !important;} 
.companybanks-table table tbody tr:nth-child(even){background-color: rgba(0, 0, 0, 0.04);}
.companybanks-table table tbody tr:hover{background-color: #ededed;}
.companybanks-table table tbody tr.hover-none:hover{background-color: transparent;}
.companybanks-table table th{white-space: nowrap;}
.companybanks-table table thead{  background: var(--bgDarkGrey);}
.ant-pagination-item-active,.ant-pagination-item:focus-visible, .ant-pagination-item:hover,.ant-pagination-item:hover a,.ant-pagination-prev:hover .ant-pagination-item-link,.ant-pagination-next:hover .ant-pagination-item-link,.ant-pagination .ant-select:not(.ant-select-disabled):hover .ant-select-selector{border-color: #ffdb1a;color: #000;}
.ant-pagination-item-active a,.ant-pagination .ant-select-item-option-selected:not(.ant-select-item-option-disabled){color:#000 ;background-color: #ffdb1a !important;}
.ant-pagination-item{height: 31px;}
.icon.Copy{background-position: -469px -272px;}
.word-break{word-break: break-all;}
.egnyte-label .ant-form-item-label > label{height: auto;}
.ibancolor{padding: 4px;border-radius: 24px;text-align: center;}
.ibancolor.green{background-color: rgb(0, 141, 0);color: #fff;}
.ibancolor.red{background-color: rgb(255, 0, 0);color: #fff;}
.ibancolor.blue{background-color: rgb(0, 38, 255);color: #fff;}
.ibancolor.orange{background-color: rgb(255, 166, 0);color: #fff;}
.darkorange{background-color: darkorange;}
.yellow{background-color: yellow;}
.red{background-color: red;}
.lightgreen{background-color: lightgreen;}
.darkgreen{background-color: darkgreen;}
.lightorange{background-color: rgba(250, 190, 106, 0.984);}
.Re-Run{background-position:-531px -273px; width: 24px !important;}
.batch-deductions{background-position: -563px -272px; scale: 1.1;}
.poa-table.control-ownership th,.poa-table.control-ownership td{white-space: nowrap;}
.min-h-22{min-height: 22px;}
.company-check th,.company-check tbody tr td:nth-child(5),.company-check tbody tr td:nth-child(14){white-space: nowrap;}
.btn-transparent{border: none;}
.icon.md.refresh{background-position: -818px -58px;    scale: 0.8;}
.icon.introducers{background-position: -1192px -84px;}
.ant-menu-item-selected .icon.introducers{background-position: -1195px -122px;}
.info-black{background-position:-78px -325px;width: 24px !important;height: 24px !important;scale: 0.9;}
.custom-modal-info .record-history{margin-top: 0 !important;}
